import React from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { red } from "@material-ui/core/colors";

import {
  Avatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";

import { Add as AddIcon, Delete } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { BpNotifications } from "../../services";

import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

const BigAvatar = styled(Avatar)`
  height: 32px;
  width: 32px;
`;

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const resList = await BpNotifications.list(page);
      setData(resList.data);
    })();
  }, [page]);

  const onDeleteClick = async (notification) => {
    if (
      window.confirm("Tem certeza que quer remover a notificação selecionada?")
    ) {
      await BpNotifications.remove(notification.id);

      const resList = await BpNotifications.list(page);
      setData(resList.data);
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Mensagem</TableCell>
                <TableCell>Corretor</TableCell>
                <TableCell>Criado Em</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow hover={true}>
                    <TableCell id={index} scope="row">
                      <Box>
                        <strong>{row.title || "Sem título"}</strong>
                        <Box>{row.message}</Box>
                      </Box>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        {row.broker?.image?.url ? (
                          <img
                            src={row.broker?.image?.url}
                            width="32"
                            height="32"
                            style={{ borderRadius: 40 }}
                          />
                        ) : (
                          <BigAvatar alt={row.broker.name} />
                        )}
                        <Box ml={3}>
                          <strong>{row.broker.name}</strong>
                          <Box>{row.broker.email}</Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <strong>{row.adminUser?.name}</strong>
                      <Box>
                        {format(parseISO(row.createdAt), "Pp", {
                          locale: pt,
                        })}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="delete"
                        onClick={() => onDeleteClick(row)}
                        style={{ color: red[300] }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelDisplayedRows={({ from, to, count }) =>
            `Página ${page + 1} de ${Math.ceil(count / 6)}`
          }
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const NotificationsIndexPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Notificações" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Notificações
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Notificações</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            href="/bp/notifications/new"
          >
            Novo
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

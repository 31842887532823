import React from "react";
import styled, { withTheme } from "styled-components/macro";

import { Avatar as MuiAvatar, Button, Grid } from "@material-ui/core";

const Avatar = styled(MuiAvatar)`
  height: 65px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
  width: 65px;
`;

const GNAvatarUploader = (props) => {
  const onFileChange = (e) => {
    const { files } = e.target;

    if (files.length) {
      const file = files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (props.onChange)
          props.onChange({
            name: file.name,
            type: file.type,
            data: reader.result,
          });
      };
    }
  };

  return (
    <Grid container>
      <Grid item container md={3} alignItems="center">
        <Avatar src={props.src} />
      </Grid>
      <Grid item container md={9} alignItems="center">
        <input
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          id="gn-avatar-uploader"
          multiple={props.multiple}
          onChange={onFileChange}
        />
        <label htmlFor="gn-avatar-uploader">
          <Button
            variant="contained"
            color="primary"
            component="span"
            size="small"
          >
            {props.buttonLabel || "Alterar foto"}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default withTheme(GNAvatarUploader);

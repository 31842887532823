import React, { useState } from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import AdminForm from "../../components/AdminForm";

import { AdminUsersService } from "../../services";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

export const AdminUsersFormPage = () => {
  const [data, setData] = useState({});

  const handleSubmit = async (form) => {
    await AdminUsersService.create(form);
    alert("Administrador cadastrado com sucesso");
  };

  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Cadastro de administrador
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/platform/admins">
          Administradores
        </Link>
        <Typography>Cadastro de administradores</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Preencha os dados abaixo para cadastrar um administrador
              </Typography>

              <AdminForm data={data} handleSubmit={handleSubmit} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

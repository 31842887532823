import React from "react";
import styled from "styled-components/macro";

import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField as MuiTextField,
    MenuItem,
    Select,
    Button as MuiButton,
} from "@material-ui/core";

import {
    Add as AddIcon,
    CloseOutlined,
    AddOutlined,
    Label,
    AppsOutlined,
} from "@material-ui/icons";

import { display, spacing } from "@material-ui/system";

// DragNDrop imports
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { padding } from "polished";

const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
// import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

const DropdownList = ({
    index,
    field,
    onFieldChange
}) => {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const options = reorder(
            field?.data?.options,
            result.source.index,
            result.destination.index
        );

        onFieldChange(index, "data", {
            ...field.data,
            options,
        });
    }

    return (
        <>
            <Grid item md={12}>
                <FormControl fullWidth variant="outlined" m={2}>
                    <InputLabel
                        id={`field_${index}_data_extra_params`}
                    >
                        Quantidade de campos extras
                    </InputLabel>
                    <Select
                        labelId={`field_${index}_data_extra_params`}
                        id={`field_${index}_data_extra_params`}
                        value={field.data.numberOfExtraParameters}
                        onChange={(e) =>
                            onFieldChange(index, "data", {
                                ...field.data,
                                numberOfExtraParameters: e.target.value,
                            })
                        }
                        label="Quantidade de campos extras"
                    >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item md={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {field?.data?.options?.map((item, optionIndex) => (
                                    <Draggable key={`item-${item.id}`} draggableId={`item-${item.id}`} index={optionIndex}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        display: "flex",
                                                    }}>
                                                    <AppsOutlined
                                                        size="large"
                                                        style={{ paddingRight: "2px" }}
                                                    />
                                                    <div
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid rgba(0,0,0,0.12)",
                                                            borderRadius: 4,
                                                            marginBottom: 8,
                                                            padding: "8px 16px 16px",
                                                            position: "relative",
                                                            width: "100%"
                                                        }}
                                                        key={`item-${item.id}`}
                                                    >

                                                        <IconButton
                                                            style={{
                                                                position: "absolute",
                                                                right: 8,
                                                                top: 4,
                                                                zIndex: 2,
                                                                color: "#fa0000",
                                                            }}
                                                            size="small"
                                                            onClick={() => {
                                                                const newList = field.data.options.filter(
                                                                    (option) => option.id !== item.id
                                                                );

                                                                onFieldChange(index, "data", {
                                                                    ...field.data,
                                                                    options: newList,
                                                                });
                                                            }}
                                                        >
                                                            <CloseOutlined />
                                                        </IconButton>

                                                        <TextField
                                                            name={item.id}
                                                            value={item.name}
                                                            fullWidth
                                                            my={1}
                                                            label={`Título da Opção #${index + 1}`}
                                                            onChange={(e) => {
                                                                const newList = field.data.options.map((i) => {
                                                                    if (i.id === item.id) {
                                                                        return {
                                                                            ...item,
                                                                            name: e.target.value
                                                                        }
                                                                    }

                                                                    return i;
                                                                })

                                                                onFieldChange(index, "data", {
                                                                    ...field.data,
                                                                    options: newList,
                                                                });
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment
                                                                        position="start">
                                                                        <Label />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />

                                                        {new Array(field.data.numberOfExtraParameters)
                                                            .fill(null)
                                                            .map((_, i) => (
                                                                <TextField
                                                                    name={`${item.id}_extra_parameter_${i}`}
                                                                    value={item.extra[i]}
                                                                    fullWidth
                                                                    my={1}
                                                                    label={`Parâmetro Extra ${i + 1
                                                                        } da Opção #${index + 1}`}
                                                                    onChange={(e) => {
                                                                        const newList = [...field.data.options];

                                                                        newList[item.id - 1].extra[i] =
                                                                            e.target.value;

                                                                        onFieldChange(index, "data", {
                                                                            ...field.data,
                                                                            options: newList,
                                                                        });
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>


                <div style={{ paddingTop: "12px" }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                            onFieldChange(index, "data", {
                                ...field.data,
                                options: [
                                    ...field.data.options,
                                    {
                                        id: field.data.options.length + 1,
                                        name: `Opção ${field.data.options.length + 1
                                            }`,
                                        extra: [],
                                    },
                                ],
                            });
                        }}
                    >
                        <AddOutlined />
                        Adicionar linha
                    </Button>
                </div>
            </Grid>
        </>
    )
}

export default DropdownList;
import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { red, blue, grey } from "@material-ui/core/colors";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { BpProjects } from "../../services";

const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const StyledTableCell = styled(TableCell)({
  paddingRight: "10%",
  textAlign: "right",
});

export const ProjectsIndexPage = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteProject, setDeleteProjects] = React.useState(null);
  const [receiveInput, setReceiveInput] = React.useState("");

  const handleClickOpen = ({ id, name }) => {
    setOpen(true);
    setDeleteProjects({ id, name });
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteProjects(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await BpProjects.list(page, search);
      setData(resList.data);
    })();
  }, [page, search]);

  const handleRemove = async () => {
    await BpProjects.remove(deleteProject.id);

    alert("Empreendimento excluido com sucesso");

    handleClose();
    const resList = await BpProjects.list(page);
    setData(resList.data);
  };

  const handleInput = (event) => {
    setReceiveInput(event.target.value);
  };

  return (
    <Box p={8}>
      <Helmet title="Empreendimentos" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Empreendimentos
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Empreendimentos</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item justify="space-between" container xs={4} alignItems="right">
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Filtro"
              variant="outlined"
              onChange={(event) => setSearch(event.target.value)}
              value={search}
              style={{ textAlign: "left" }}
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Icons.Add />}
              href="/bp/projects/new"
            >
              Novo
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Código</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Nome</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Criado por</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Alterado por</strong>
                      </TableCell>
                      <StyledTableCell>
                        <strong>Ações</strong>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow hover={true}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell id={index} scope="row">
                            <Box>
                              <strong>{row.name}</strong>
                              <br />
                              {row.kind === "residential" ? "Residencial" : ""}
                              {row.kind === "business" ? "Empresarial" : ""}
                              {row.kind === "area" ? "Área" : ""}
                              {row.kind === "mixed" ? "Misto" : ""}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {row.createBy} -{" "}
                            {format(parseISO(row.createdAt), "P", {
                              locale: pt,
                            })}
                          </TableCell>
                          <TableCell>
                            {row.updateBy} -{" "}
                            {format(parseISO(row.updatedAt), "P", {
                              locale: pt,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              component={NavLink}
                              to={`/bp/projects/${row.id}/roulette-logs`}
                              style={{
                                color:
                                  row.rouletteEnabled ||
                                  row.rouletteEnabledForAdmin
                                    ? blue[700]
                                    : grey[300],
                              }}
                            >
                              {row.rouletteEnabled ||
                              row.rouletteEnabledForAdmin ? (
                                <Icons.ThumbUp />
                              ) : (
                                <Icons.ThumbDown />
                              )}
                            </IconButton>

                            <IconButton
                              disabled={
                                !(row.published || row.publishedForAdmin)
                              }
                              component={NavLink}
                              to={`/bp/projects/${row.id}/maps`}
                              style={{
                                color:
                                  row.published || row.publishedForAdmin
                                    ? blue[700]
                                    : grey[300],
                              }}
                            >
                              <Icons.Map />
                            </IconButton>

                            <IconButton
                              component={NavLink}
                              to={`/bp/projects/${row.id}/lots`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.RemoveRedEye />
                            </IconButton>
                            <IconButton
                              component={NavLink}
                              to={`/bp/projects/${row.id}/edit`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.Create />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleClickOpen({ id: row.id, name: row.name })
                              }
                              style={{ color: red[300] }}
                            >
                              <Icons.Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {deleteProject?.name && deleteProject?.id && (
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    {`Deseja excluir '${deleteProject?.name}'?`}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Para excluir{" "}
                      <strong style={{ color: "red" }}>
                        {deleteProject?.name}
                      </strong>{" "}
                      <strong>definitivamente</strong> digite o nome do
                      empreendimento no campo abaixo
                    </DialogContentText>
                  </DialogContent>
                  <div align="center">
                    <TextField
                      labelId="label"
                      my={2}
                      variant="outlined"
                      onChange={(event) => handleInput(event)}
                      value={receiveInput}
                    ></TextField>
                  </div>
                  <DialogActions>
                    {receiveInput == deleteProject?.name ? (
                      <Button
                        style={{ color: "red" }}
                        onClick={handleRemove}
                        color="primary"
                      >
                        Deletar
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 6)}`
                }
                rowsPerPageOptions={[6]}
                component="div"
                count={data.count || 0}
                rowsPerPage={6}
                page={page}
                onChangePage={(_, page) => setPage(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { blue, green, red } from "@material-ui/core/colors";

import Autocomplete from "../../components/Autocomplete";

import {
  Avatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
} from "@material-ui/core";

import {
  RemoveRedEye as RemoveRedEyeIcon,
  Create,
  Work as WorkIcon,
} from "@material-ui/icons";

import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";

import { spacing } from "@material-ui/system";

import { BpBrokers, BpRealStates } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

export const RealStatesBrokersIndexPage = () => {
  const { realstateId } = useParams();

  const [search, setSearch] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [realState, setRealState] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const resRealState = await BpRealStates.getById(realstateId);
      setRealState(resRealState.data);

      const resBrokers = await BpRealStates.listBrokers(realstateId, 0, search);
      setData(resBrokers.data);
    })();
  }, []);

  const onSubmitSearch = async (search) => {
    const resBrokers = await BpRealStates.listBrokers(realstateId, 0, search);
    setData(resBrokers.data);

    setPage(0);
  };

  const handlePageChange = async (page) => {
    const resBrokers = await BpRealStates.listBrokers(
      realstateId,
      page,
      search
    );

    setData(resBrokers.data);

    setPage(page);
  };

  const autoCompleteChange = (option) => {
    onSubmitSearch(option?.value);
  };

  const loadOptions = async (inputValue, callback) => {
    const resList = await BpBrokers.list(0, inputValue);

    const autoCompleteOptions = resList?.data?.rows?.length
      ? resList.data.rows.map((row) => {
          return {
            value: row.name,
            label: row.name,
          };
        })
      : [];

    callback(autoCompleteOptions);
  };

  return (
    <Box p={8}>
      <Helmet title="Corretores Imobiliária" />

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/realstates">
          Imobiliárias
        </Link>
        <Link
          component={NavLink}
          exact
          to={`/bp/realstates/${realstateId}/edit`}
        >
          {realState?.name}
        </Link>
        <Typography>Corretores</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="right" style={{ minWidth: "150px" }}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!data?.rows?.length && (
                      <h3 style={{ padding: 12 }}>
                        Corretor(a) não encontrado(a)
                      </h3>
                    )}
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow id={index} hover={true}>
                          <TableCell scope="row">
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {row.image?.url ? (
                                <img
                                  src={row.image?.url}
                                  width="40"
                                  height="40"
                                  style={{ borderRadius: 40 }}
                                />
                              ) : (
                                <Avatar />
                              )}
                              <Box ml={3}>
                                <strong>{row.name}</strong>
                                <Box>{row.email}</Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: "180px" }}
                          >
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/roulettes`}
                              style={{ color: blue[700] }}
                            >
                              <WorkIcon />
                            </IconButton>

                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/lots`}
                              style={{ color: blue[700] }}
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>

                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/edit`}
                              style={{ color: blue[700] }}
                            >
                              <Create />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 6)}`
                }
                rowsPerPageOptions={[6]}
                component="div"
                count={data.count || 0}
                rowsPerPage={6}
                page={page}
                onChangePage={(_, page) => handlePageChange(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

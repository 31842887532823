import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { CpFormsService } from "../../services";

import { FormsOpenPage } from "./FormsOpenPage";

const FormEditPage = () => {
    const { formId } = useParams();
    const [data, setData] = useState([]);

    React.useEffect(() => {
        (async () => {
            const resList = await CpFormsService.getById(formId);

            setData(resList.data);
        })();
    }, []);

    const handleSubmit = async (form) => {
        await CpFormsService.edit(formId, form);

        alert("Formulário atualizado com sucesso")
    }

    return (
        <FormsOpenPage data={data} buttonName="Atualizar" formTitle="Editar formulário" handleSubmit={handleSubmit} />
    );
}

export { FormEditPage };
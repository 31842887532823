import React from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpNotifications } from "../../services";

import NotificationsForm from "../../components/NotificationsForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
  const createNotifications = async (form) => {
    if (form.all) {
      await BpNotifications.createAll(form);
    } else {
      await BpNotifications.create(form);
    }

    alert("Notificação cadastrada com sucesso");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preencha os dados abaixo para criar uma nova notificação
        </Typography>

        <NotificationsForm
          buttonOnClick={createNotifications}
          buttonName="Cadastrar"
          handleSubmit={createNotifications}
        />
      </CardContent>
    </Card>
  );
}

export const NotificationsFormPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Nova Notificação
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/Notifications">
          Notificações
        </Link>
        <Typography>Cadastro de Notificações</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </Box>
  );
};

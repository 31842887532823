import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBlDhZfzGfQfSUqFw6QPq04ayPBtIrjW2o",
  authDomain: "portal-corretor-admin.firebaseapp.com",
  databaseURL: "https://portal-corretor-admin.firebaseio.com",
  projectId: "portal-corretor-admin",
  storageBucket: "portal-corretor-admin.appspot.com",
  messagingSenderId: "1060743028619",
  appId: "1:1060743028619:web:3acbdc4d68040756e0598e",
  measurementId: "G-J7N85L4F05",
};

firebase.initializeApp(config);

const auth = firebase.auth();

export const getCurrentUser = () =>
  new Promise((resolve) => {
    auth.onAuthStateChanged(resolve);
  });

export const signIn = (credentials) => {
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(resolve)
      .catch((err) => {
        if (["auth/user-not-found", "auth/wrong-password"].includes(err.code)) {
          reject({ message: "Email ou senha inválidos" });
        } else {
          reject({ message: "Ocorreu um erro inexperado" });
        }
      });
  });
};

export const signOut = () => auth.signOut();

import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  MenuItem,
  InputLabel,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  DeleteOutlined,
  CreateOutlined,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { IconButton } from "@material-ui/core";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Avatar = styled(MuiAvatar)``;

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  height: 65px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
  width: 65px;
`;

function BannersForm({ data, buttonName, handleSubmit }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [order, setOrder] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [image, setImage] = useState({});
  const [imageBlob, setImageBlob] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTitle(data?.title || "");
    setDescription(data?.description || "");
    setURL(data?.url || "");
    setOrder(data?.order || "");
    setEnabled(data?.enabled || false);

    setImage(data?.image || "");

    setImageBlob(data?.image ? data.image.data : "");
  }, [data]);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    let imageData = {};

    const formData = {
      title,
      description,
      url,
      order,
      enabled,
      icon: "baloon",
    };

    if (image) {
      imageData = {
        image: {
          imageType: image.type,
          name: image.name,
          data: image.data,
        },
      };
    }

    const form = {
      ...formData,
      ...imageData,
    };

    try {
      await handleSubmit(form);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleInputFileChangeIcon = (event) => {
    const { files } = event.target;

    if (files.length) {
      const file = files[0];
      const blob = URL.createObjectURL(file);
      setImageBlob(blob);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = {
          name: file.name,
          type: file.type,
          data: reader.result,
        };
        setImage(img);
      };
    }
  };

  const handleRemoveImage = () => {
    setImageBlob("");
    setImage("");
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="title"
                    label="Título"
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleChange(setTitle, event.target.value)
                    }
                    value={title}
                    my={2}
                  />
                </Grid>
              </Grid>

              <TextField
                id="description"
                label="Descrição"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setDescription, event.target.value)
                }
                value={description}
                my={2}
              />

              <TextField
                id="url"
                label="URL"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setURL, event.target.value)}
                value={url}
                my={2}
              />

              <TextField
                id="ordem"
                label="Ordem"
                variant="outlined"
                onChange={(event) => handleChange(setOrder, event.target.value)}
                value={order}
                fullWidth
                my={2}
              />

              <TextField
                label="Ativo"
                onChange={(event) =>
                  handleChange(setEnabled, event.target.value === "true")
                }
                value={enabled ? "true" : "false"}
                labelId="label"
                id="select"
                select
                my={2}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="true">Sim</MenuItem>
                <MenuItem value="false">Não</MenuItem>
              </TextField>

              <Grid item md={12}>
                <CenteredContent>
                  <BigAvatar
                    alt="Remy Sharp"
                    src="/static/img/avatars/upload.png"
                  />
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleInputFileChangeIcon}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Alterar imagem para banner
                    </Button>

                    <Typography variant="caption" display="block" gutterBottom>
                      use uma imagem de 128 x 128 pixels no formato .jpg
                    </Typography>
                  </label>

                  {imageBlob && (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        alt="background image"
                        height="250px"
                        src={imageBlob}
                        width="250px"
                      />
                      <IconButton onClick={handleRemoveImage}>
                        <HighlightOffIcon />
                        <Typography
                          variant="caption"
                          gutterBottom
                          color="error"
                        >
                          Remover imagem
                        </Typography>
                      </IconButton>
                    </div>
                  )}
                </CenteredContent>
              </Grid>

              <Button
                onClick={submitForm}
                variant="contained"
                color="primary"
                type="submit"
                mt={3}
              >
                {buttonName}
              </Button>
            </CardContent>
          </Card>
        </form>
      )}
    </>
  );
}

export default BannersForm;

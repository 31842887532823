import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { yellow } from '@material-ui/core/colors';

import { UserSection } from "../../services";

const Card = styled(MuiCard)(spacing);

const BigAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
`;

export const HomePage = () => {
  const [name, setName] = useState("");
  const [data, setUserData] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const resList = await UserSection.getUser();
      setUserData(resList.data);
    })();
  }, []);

  useEffect(() => {
    setName(data?.name || "")

  }, [data])

  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", backgroundColor: "#f5f6fb", padding: "12px" }}>
        <Typography style={{ color: yellow[700], fontSize: "22px", padding: "12px" }}>Olá,</Typography>
        <BigAvatar
          alt="Remy Sharp"
          src="/static/img/avatars/avatar-user.jpg"
        />
      </div>
      <img
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src="/img_background.png"
        alt="Imagem de boas vindas"
      />
    </Card>
  );
};

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { AuthService } from "../services";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const [auth, setAuth] = useState(null);
  const [isAuthResolved, setIsAuthResolved] = useState(false);

  useEffect(() => {
    AuthService.getCurrentUser().then((auth) => {
      setAuth(auth);
      setIsAuthResolved(true);
    });
  }, []);

  if (isAuthResolved) {
    if (auth) {
      return children;
    }

    return <Redirect to="/auth/sign-in" />;
  }

  return null;
}

export default AuthGuard;

/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { blue } from "@material-ui/core/colors";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import * as Icons from "@material-ui/icons";
// import Modal from "@mui/material/Modal";

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { CpCollaboratorsService } from "../../services";

const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  height: 65px;
  width: 65px;
`;
const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const resList = await CpCollaboratorsService.list(page);
      setData(resList.data);
    })();
  }, [page]);

  const freeAccess = async (id, enabled) => {
    alert("Acesso ao portal liberado com sucesso!");
    try {
      await CpCollaboratorsService.accessMobile(id);
    } catch (err) {
      console.log("Ops:", err);
    }
    const resList = await CpCollaboratorsService.list(page);
    setData(resList.data);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>
                  Data de Criação
                  <br />
                  Data de Atualização
                </TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow>
                    <TableCell component="th" id={index} scope="row">
                      <Customer>
                        {row?.["image.url"] ? (
                          <img
                            src={row?.["image.url"]}
                            width="65"
                            height="65"
                            style={{ borderRadius: 40 }}
                          />
                        ) : (
                          <Avatar
                            alt={row.name}
                            src="/static/images/avatar/1.jpg"
                          />
                        )}
                        <Box ml={3}>
                          <strong>{row.name}</strong>
                          <br />
                          {row.email}
                        </Box>
                      </Customer>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {format(parseISO(row.createdAt), "PPPP", {
                          locale: pt,
                        })}
                        <br />
                        {format(parseISO(row.updatedAt), "PPPP", {
                          locale: pt,
                        })}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/cp/collaborators/${row.id}/edit`}
                        style={{ color: blue[700] }}
                      >
                        <Icons.Create />
                      </IconButton>

                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={"#"}
                        onClick={() => freeAccess(row.id, row.enabled)}
                        style={{ color: blue[700] }}
                      >
                        {row.enabled ? null : (
                          <Icons.MobileOff color={"error"} />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const CollaboratorsIndexPage = () => {
  const history = useHistory();

  return (
    <Box p={8}>
      <Helmet title="Colaboradores" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Colaboradores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Inicio
            </Link>
            <Link component={NavLink} exact to="/">
              Portal do Colaborador
            </Link>
            <Typography>Colaboradores</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            href="/cp/collaborators/new"
          >
            Cadastrar Colaborador
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import CircularProgress from "@material-ui/core/CircularProgress";

import { CpCollaboratorsService, CpFormsService } from "../../services";

import {
  Box,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";

import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

function CollaboratorsForm({ data }) {
  const [isSubmitting, setSubmitting] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [enabled, setEnabled] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [document, setDocument] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");

  const [forms, setForms] = useState([]);
  const [accesses, setAccesses] = useState({});

  useEffect(() => {
    (async () => {
      setName(data?.name || "");
      setEmail(data?.email || "");
      setEnabled(data?.enabled || "");
      setAddressStreet(data?.addressStreet || "");
      setAddressComplement(data?.addressComplement || "");
      setAddressCity(data?.addressCity || "");
      setAddressNumber(data?.addressNumber || "");
      setAddressState(data?.addressState || "");
      setAddressZipcode(data?.addressZipcode || "");
      setAddressDistrict(data?.addressDistrict || "");
      setDocument(data?.document || "");
      setMobilePhone(data?.mobilePhone || "");

      const resList = await CpFormsService.list();
      setForms(resList?.data?.rows);

      setAccesses(
        forms.reduce(
          (obj, form) => ({
            ...obj,
            [form.id]: false,
          }),
          {}
        )
      );
    })();
  }, [data]);

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const newData = {
      name,
      enabled,
      addressStreet,
      addressComplement,
      addressCity,
      addressNumber,
      addressState,
      addressZipcode,
      addressDistrict,
      document,
      mobilePhone,
    };
    try {
      if (data?.id) {
        await CpCollaboratorsService.update(data?.id, newData);
      } else {
        await CpCollaboratorsService.create(newData);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleChange = (setInput, value) => {
    setInput(value);
  };
  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Informações Pessoais
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={8}>
                  <TextField
                    id="name"
                    label="Nome Completo"
                    defaultValue="Digite aqui o nome do colaborador"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setName, event.target.value)
                    }
                    value={name}
                  />

                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    disabled="true"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setEmail, event.target.value)
                    }
                    value={email}
                  />
                </Grid>
                <Grid item md={4}>
                  <CenteredContent>
                    <BigAvatar
                      alt="Remy Sharp"
                      src={
                        data?.image?.url
                          ? data?.image?.url
                          : "/static/img/avatars/avatar-user.jpg"
                      }
                    />
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <CloudUpload mr={2} /> Enviar Foto
                      </Button>
                    </label>
                  </CenteredContent>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>

              <TextField
                id="addressStreent"
                label="Endereço"
                variant="outlined"
                fullWidth
                my={2}
                onChange={(event) =>
                  handleChange(setAddressStreet, event.target.value)
                }
                value={addressStreet}
              />

              <Grid container spacing={6}>
                <Grid item md={4}>
                  <TextField
                    id="addressNumber"
                    label="Número"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressNumber, event.target.value)
                    }
                    value={addressNumber}
                  />
                </Grid>

                <Grid item md={4}>
                  <TextField
                    id="addressComplement"
                    label="Complemento"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressComplement, event.target.value)
                    }
                    value={addressComplement}
                  />
                </Grid>

                <Grid item md={4}>
                  <TextField
                    id="addressDistrict"
                    label="Bairro"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressDistrict, event.target.value)
                    }
                    value={addressDistrict}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={4}>
                  <TextField
                    id="city"
                    label="Cidade"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressCity, event.target.value)
                    }
                    value={addressCity}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="state"
                    label="Estado"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressState, event.target.value)
                    }
                    value={addressState}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="addressZipcode"
                    label="CEP"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressZipcode, event.target.value)
                    }
                    value={addressZipcode}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Accessos
              </Typography>

              <FormGroup>
                {forms.map((form) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accesses[form.id] || !form.private}
                        disabled={!form.private}
                        onChange={(event) =>
                          setAccesses({
                            ...accesses,
                            [form.id]: event.target.checked,
                          })
                        }
                        name={`accesses-${form.id}`}
                      />
                    }
                    label={`${form.title}${form.private ? "" : " (Público)"}`}
                  />
                ))}
              </FormGroup>
            </CardContent>
          </Card>

          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            mt={3}
          >
            Salvar
          </Button>
        </form>
      )}
    </>
  );
}

export const CollaboratorsFormPage = () => {
  const { collaboratorId } = useParams();
  const [data, setData] = useState([]);

  React.useEffect(() => {
    (async () => {
      const resList = await CpCollaboratorsService.getById(collaboratorId);

      setData(resList.data);
    })();
  }, []);

  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        {collaboratorId ? "Atualização" : "Cadastro"} de colaboradores
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/cp/collaborators">
          Colaboradores
        </Link>
        <Typography>
          {collaboratorId ? data?.email : "Novo Colaborador"}
        </Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CollaboratorsForm data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import JoditEditor from "jodit-react";
import { display, spacing } from "@material-ui/system";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
  Input,
  Paper as MuiPaper,
} from "@material-ui/core";

import styled from "styled-components/macro";

const Button = styled(MuiButton)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const EditorsTabs = ({ pageData, setPageData }) => {
  const [errors, setErrors] = useState(null);
  const [value, setValue] = React.useState(0);
  const [newTabTitle, setNewTabTitle] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddTabClick = (event) => {
    const newPageData = Object.assign({}, pageData);
    newPageData.index.push(newTabTitle);
    newPageData.content[newTabTitle] = "";

    setPageData(newPageData);
    setNewTabTitle("");
  };

  const onTabEditorBlur = (value, key) => {
    const content = Object.assign({}, pageData.content, { [key]: value });
    setPageData(Object.assign({}, pageData, { content }));
  };

  const onTabRemoveClick = (event, key) => {
    const newPageData = Object.assign({}, pageData);
    newPageData.index.splice(newPageData.index.indexOf(key), 1);
    delete newPageData.content[key];

    setPageData(newPageData);
  };

  const editorConfig = {
    uploader: { insertImageAsBase64URI: true },
    spellcheck: false,
    language: "pt_br",
    toolbarButtonSize: "large",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttons:
      "ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,fullsize,print",
    disablePlugins: "search,xpath",
  };

  return (
    <div>
      <Typography variant="h6" style={{ margin: "24px 0 16px" }}>
        Descritivo
      </Typography>

      <div style={{ display: "flex" }}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          {pageData?.index?.map((key, index) => (
            <LinkTab key={key} label={key} {...a11yProps(index)} />
          ))}
        </Tabs>
        <Input
          name="newTabTitle"
          label="Título da nova aba"
          placeholder="Título da nova aba"
          onChange={(event) => {
            setNewTabTitle(event.target.value);
          }}
          value={newTabTitle}
          error={errors && errors.newTabTitle}
        />
        <Button
          size="small"
          type="button"
          link
          disabled={newTabTitle.length === 0}
          onClick={onAddTabClick}
        >
          Adicionar aba
        </Button>
      </div>

      {pageData?.index?.map((key, index) => (
        <TabPanel key={key} value={value} index={index}>
          <JoditEditor
            value={pageData.content[key] || ""}
            config={editorConfig}
            onBlur={(value) => onTabEditorBlur(value, key)}
          />

          <Button
            size="small"
            type="button"
            link
            onClick={(event) => onTabRemoveClick(event, key)}
          >
            Remover aba
          </Button>
        </TabPanel>
      ))}
    </div>
  );
};

export default EditorsTabs;

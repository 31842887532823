import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { blue } from "@material-ui/core/colors";

import {
  Box as MuiBox,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { Delete, Create } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { BpBrokers, BpBrokerRoulettes } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

export const BrokersRoulettesPage = () => {
  const [broker, setBroker] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);

  const { brokerId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resBroker = await BpBrokers.getById(brokerId);
      setBroker(resBroker.data);
    })();
  }, [brokerId]);

  React.useEffect(() => {
    (async () => {
      const resList = await BpBrokerRoulettes.list(brokerId, page);
      setData(resList.data);
    })();
  }, [brokerId, page]);

  return (
    <Box p={8}>
      <Helmet title="Roleta Virtual" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lista de Roletas Cadastradas
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/bp/brokers">
              Corretores
            </Link>
            <Link component={NavLink} exact to={`/bp/brokers/${brokerId}/edit`}>
              {broker?.name}
            </Link>
            <Typography>Roletas</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size={"medium"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>EMPREENDIMENTOS</TableCell>
                      <TableCell>
                        STATUS
                        <br />
                        COMENTÁRIO
                      </TableCell>
                      <TableCell>
                        ALTERADO POR
                        <br />
                        ALTERADO EM
                      </TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow
                          style={{
                            background:
                              row.status === "CREATED"
                                ? "#ffffff"
                                : row.status === "IN_ANALYSIS"
                                ? "#FEFEAE"
                                : row.status === "APPROVED"
                                ? "#f0fff0"
                                : row.status === "REPROVED"
                                ? "#ffeeed"
                                : row.status === "BLOCKED"
                                ? "#ffeeed"
                                : "#ffffff",
                          }}
                        >
                          <TableCell align="left" id={index} scope="row">
                            <strong>{row.project.name}</strong>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <Box>
                              <strong>
                                {row.status === "CREATED"
                                  ? "Não solicitado"
                                  : ""}
                                {row.status === "IN_ANALYSIS"
                                  ? "Aguardando Análise"
                                  : ""}
                                {row.status === "APPROVED" ? "Aprovado" : ""}
                                {row.status === "REPROVED" ? "Reprovado" : ""}
                                {row.status === "BLOCKED" ? "Bloqueado" : ""}
                              </strong>
                              <Box>{row.comment}</Box>
                            </Box>
                          </TableCell>
                          <TableCell align="left" id={index} scope="row">
                            <Box>
                              <strong>{row.adminUser?.name}</strong>
                              <Box>{row.adminUserUpdatedAt}</Box>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${brokerId}/roulettes/${row.id}`}
                              style={{ color: blue[700] }}
                            >
                              <Create />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 6)}`
                }
                rowsPerPageOptions={[6]}
                component="div"
                count={data.count || 0}
                rowsPerPage={6}
                page={page}
                onChangePage={(_, page) => setPage(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

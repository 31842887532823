import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import NumberFormat from "react-number-format";

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpLots } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  align-items: center;
  display: flex;
`;

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const { projectId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpLots.list(projectId, page);
      setData(resList.data);
    })();
  }, [page]);

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>IDENTIFICAÇÃO DA UNIDADE</TableCell>
                <TableCell>ÁREA REAL (M²)</TableCell>
                <TableCell>PREÇO FINAL DO LOTE (R$/M²)</TableCell>
                <TableCell>VALOR FINAL DO LOTE</TableCell>
                <TableCell>STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow
                    style={{
                      background: row.status.includes("VENDA")
                        ? "#f0fff0"
                        : "#ffeeed",
                    }}
                  >
                    <TableCell id={index} scope="row">
                      <Customer>
                        <Box>
                          <strong>{row.identifier}</strong>
                          <br />
                          <Typography>Comercial - Frente | Avenida</Typography>
                        </Box>
                      </Customer>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Customer>
                        <NumberFormat
                          value={row.area / 100}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          displayType="text"
                          fixedDecimalScale="true"
                        />
                      </Customer>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Customer>
                        <NumberFormat
                          value={row.areaPrice / 100}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          displayType="text"
                          fixedDecimalScale="true"
                          prefix="R$ "
                        />
                      </Customer>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Customer>
                        <NumberFormat
                          value={row.finalPrice / 100}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          displayType="text"
                          fixedDecimalScale="true"
                          prefix="R$ "
                        />
                      </Customer>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Customer>
                        <Box>{row.status}</Box>
                      </Customer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const LotsTablePage = () => {
  return (
    <Box p={8}>
      <Helmet title="Lotes do empreendimento" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lotes do empreendimento
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Empreendimentos</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

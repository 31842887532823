import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import {
    Button as MuiButton,
    TextField as MuiTextField,
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    Card as MuiCard,
    CardContent,
    Box,
    Grid,
    Breadcrumbs,
    Typography,
    Link,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { spacing } from "@material-ui/system";

import BannersForm from "../../components/BannersForm";

import { BpBanners } from "../../services";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


function Private() {
    const [data, setData] = useState([]);
    const [name, setName] = useState("");

    const { bannerId } = useParams();

    React.useEffect(() => {
        (async () => {
            const resList = await BpBanners.getById(bannerId);
            setData(resList.data);
        })();
    }, []);

    useEffect(() => {
        setName(data?.name || "")

    }, [data])

    const handleSubmit = async (form) => {
        await BpBanners.edit(bannerId, form);

        alert("Banner atualizado com sucesso")
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Preencha os dados abaixo para editar os dados do banner
                </Typography>
                <BannersForm data={data} buttonName="Atualizar" handleSubmit={handleSubmit} />
            </CardContent>
        </Card>
    );
}

export const BannersEditFormPage = () => {
    return (
        <Box p={8}>
            <Helmet title="Settings" />

            <Typography variant="h3" gutterBottom display="inline">
                Editar banner
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} exact to="/">
                    Inicio
                </Link>
                <Link component={NavLink} exact to="/bp/banners">
                    Banners
                </Link>
                <Typography>Edição de banners</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Private />
                </Grid>
            </Grid>
        </Box>
    );
};
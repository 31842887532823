import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import { spacing } from "@material-ui/system";
import { BpBrokers } from "../services";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

function NotificationsForm({ buttonName, handleSubmit }) {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [all, setAll] = useState(false);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [brokerValue, setBrokerValue] = useState(null);
  const [brokerInput, setBrokerInput] = useState("");

  React.useEffect(() => {
    (async () => {
      if (brokerInput === "") {
        setBrokerOptions(brokerValue ? [brokerValue] : []);
        return undefined;
      }

      const resList = await BpBrokers.list(0, brokerInput);

      setBrokerOptions(resList.data.rows);
    })();

    return () => {};
  }, [brokerInput]);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!title || !message) {
      return alert("Todos os campos são obrigatórios");
    }

    if (!all && !brokerValue?.id) {
      return alert("Escolha um corretor");
    }

    setSubmitting(true);

    const formData = {
      title,
      message,
      brokerId: brokerValue?.id,
      all,
    };

    try {
      await handleSubmit(formData);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Card mb={6}>
            <CardContent>
              <TextField
                id="title"
                label="Título"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setTitle, event.target.value)}
                value={title}
                my={2}
              />

              <TextField
                id="message"
                label="Mensagem"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setMessage, event.target.value)
                }
                value={message}
                my={2}
              />

              <Autocomplete
                disabled={all}
                onInputChange={(_, newInputValue) =>
                  setBrokerInput(newInputValue)
                }
                onChange={(event, newValue) => {
                  setBrokerValue(newValue);
                }}
                getOptionSelected={(option, value) =>
                  option.email === value.email
                }
                getOptionLabel={(option) =>
                  `${option.name} (${option.email})` || ""
                }
                options={brokerOptions}
                value={brokerValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="broker"
                    label="Corretor"
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                )}
                renderOption={(option) => {
                  return (
                    <div>
                      <div>
                        <strong>{option.name}</strong>
                      </div>
                      <div>{option.email}</div>
                    </div>
                  );
                }}
              />

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={all}
                      onChange={(event) => setAll(event.target.checked)}
                      name="all"
                    />
                  }
                  label="Enviar para todos os corretores ativos"
                />
              </div>

              <Button
                style={{ marginTop: 16 }}
                onClick={submitForm}
                variant="contained"
                color="primary"
                type="submit"
              >
                {buttonName}
              </Button>
            </CardContent>
          </Card>
        </form>
      )}
    </>
  );
}

export default NotificationsForm;

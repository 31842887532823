import React from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";

import { FormsOpenPage } from "./FormsOpenPage";
import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { CpFormsService } from "../../services";

const Box = styled(MuiBox)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "22px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const FormsFormPage = () => {

  const onSaveClick = async (form) => {
    await CpFormsService.create(form);

    alert("Formulário Cadastrado com Sucesso");
  };

  return (
    <FormsOpenPage buttonName="Adicionar" formTitle="Novo formulário" handleSubmit={onSaveClick} />
  );
};

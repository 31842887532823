import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { red, blue } from '@material-ui/core/colors';

import {
  CardActionArea,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { Delete, Edit, FileCopy, } from "@material-ui/icons";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';


const CardContent = styled(MuiCardContent)`
  height: 200px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Typography = styled(MuiTypography)(spacing);

export const FormCard = ({
  handleRemoveAction,
  duplicateForm,
  id,
  title,
  description,
  onClick,
}) => {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" color="primary">
            {title}
          </Typography>
          <Typography mb={4} component="p">
            {description}
          </Typography>
        </CardContent>

        <CardActions style={{ justifyContent: 'flex-end' }} disableSpacing>
          <IconButton>
            <Delete
              onClick={() => handleRemoveAction(id)}
              style={{ color: red[300] }} />
          </IconButton>
          <IconButton
            aria-label="details"
            component={NavLink}
            to={`/cp/forms/${id}/edit`}
            style={{ color: blue[700] }}
          >
            <Edit />
          </IconButton>
          <IconButton>
            <FileCopy
              onClick={() => duplicateForm(id)}
              style={{ color: blue[700] }} />
          </IconButton>
        </CardActions>
      </CardActionArea>
    </Card >
  );
};

import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Button as MuiButton,
  Grid,
  MenuItem,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function AdminForm({ data, handleSubmit }) {
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");


  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setName(data?.name || "");
    setDocument(data?.document || "");
    setEmail(data?.email || "");
    setRole(data?.role || "");
  }, [data]);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const form = {
      name,
      document,
      email,
      role,
      password,
    };
    try {
      await handleSubmit(form);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <TextField
                id="abbreviation"
                label="Nome"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setName, event.target.value)}
                value={name}
                my={2}
              />
            </Grid>
          </Grid>

          <TextField
            label="Documento"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setDocument, event.target.value)}
            value={document}
            my={2}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setEmail, event.target.value)}
            value={email}
            my={2}
          />
          <TextField
            label="Perfil"
            variant="outlined"
            fullWidth
            select
            onChange={(event) => handleChange(setRole, event.target.value)}
            value={role}
            my={2}
          >
            <MenuItem value="admin">Administrador</MenuItem>
            <MenuItem value="regular">Regular</MenuItem>
            <MenuItem value="readonly">Leitura</MenuItem>
          </TextField>
          {!data.id && (
            <TextField
              label="Senha"
              variant="outlined"
              fullWidth
              onChange={(event) =>
                handleChange(setPassword, event.target.value)
              }
              value={password}
              my={2}
            />
          )}

          <Button
            variant="contained"
            color="primary"
            mt={3}
            fullWidth
            onClick={submitForm}
          >
            {data.id ? "Atualizar" : "Cadastrar"}
          </Button>
        </form>
      )}
    </>
  );
}

export default AdminForm;

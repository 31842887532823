import React from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { red, green, blue } from "@material-ui/core/colors";

import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { Add as AddIcon, Delete, People, Create } from "@material-ui/icons";

import Brightness1Icon from "@material-ui/icons/Brightness1";

import CheckIcon from "@material-ui/icons/Check";

import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";

import { BpRealStates } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(4),
  },
}));

export const RealStatesIndexPage = () => {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteRealState, setDeleteRealState] = React.useState(null);
  const [receiveInput, setReceiveInput] = React.useState("");

  React.useEffect(() => {
    (async () => {
      const resList = await BpRealStates.list(page, search);
      setData(resList.data);
    })();
  }, []);

  const onSubmitSearch = async (search) => {
    const resList = await BpRealStates.list(0, search);
    setData(resList.data);

    setPage(0);
  };

  const handleClickOpen = ({ id, name }) => {
    setOpen(true);
    setDeleteRealState({ id, name });
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteRealState(null);
  };

  const handleRemove = async () => {
    await BpRealStates.remove(deleteRealState.id);

    alert("Imobiliaria excluida com sucesso");

    handleClose();
    const resList = await BpRealStates.list(page);
    setData(resList.data);
  };

  const handlePageChange = async (page) => {
    const resList = await BpRealStates.list(page, search);
    setData(resList.data);

    setPage(page);
  };

  const handleInput = (event) => {
    setReceiveInput(event.target.value);
  };

  const autoCompleteChange = (event) => {
    onSubmitSearch(event?.target?.value);
  };

  return (
    <Box p={12}>
      <Helmet title="Imobiliárias" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Imobiliárias
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Imobiliárias</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="filter"
            label="Filtro"
            variant="outlined"
            fullWidth
            onChange={autoCompleteChange}
            my={2}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          href="/bp/realstates/new"
        >
          Novo
        </Button>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table size={"medium"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>CRECI</TableCell>
                      <TableCell align="center">Ativo</TableCell>
                      <TableCell align="center">Situação</TableCell>
                      <TableCell>Data de Criação</TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!data?.rows?.length && (
                      <h3 style={{ padding: 12 }}>
                        Imobiliária não encontrada
                      </h3>
                    )}
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow hover={true}>
                          <TableCell id={index} scope="row">
                            <Box>
                              <strong>{row.name}</strong>
                              <Box>{row.document}</Box>
                            </Box>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <Box>{row.creci}</Box>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "5px" }}>
                                <Brightness1Icon
                                  style={{ color: green[700], width: "10px" }}
                                />
                              </Box>
                              <Box style={{ color: green[900] }}>
                                {row.enabled ? "Sim" : "Não"}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "5px" }}>
                                <CheckIcon style={{ color: green[700] }} />
                              </Box>
                              <Box style={{ color: green[900] }}>
                                {row.status === "approved"
                                  ? "Aprovado"
                                  : "Não aprovado"}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {format(parseISO(row.createdAt), "PPPP", {
                              locale: pt,
                            })}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/realstates/${row.id}/brokers`}
                              style={{ color: blue[700] }}
                            >
                              <People />
                            </IconButton>
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/realstates/${row.id}/edit`}
                              style={{ color: blue[700] }}
                            >
                              <Create />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleClickOpen({ id: row.id, name: row.name })
                              }
                              style={{ color: red[300] }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {deleteRealState?.name && deleteRealState?.id && (
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    {"Deseja excluir " + `${deleteRealState?.name}` + "?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Para excluir{" "}
                      <strong style={{ color: "red" }}>
                        {deleteRealState?.name}
                      </strong>{" "}
                      <strong>definitivamente</strong> digite o nome da
                      Imobiliaria no campo abaixo
                    </DialogContentText>
                  </DialogContent>
                  <div align="center">
                    <TextField
                      labelId="label"
                      my={2}
                      variant="outlined"
                      onChange={(event) => handleInput(event)}
                      value={receiveInput}
                    ></TextField>
                  </div>
                  <DialogActions>
                    {receiveInput == deleteRealState?.name ? (
                      <Button
                        style={{ color: "red" }}
                        onClick={handleRemove}
                        color="primary"
                      >
                        Deletar
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button onClick={handleClose} color="primary" autoFocus>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 6)}`
                }
                rowsPerPageOptions={[6]}
                component="div"
                count={data.count || 0}
                rowsPerPage={6}
                page={page}
                onChangePage={(_, page) => handlePageChange(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

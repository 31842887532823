/*global google*/
/*global window*/
/*global confirm*/
import React from "react";
import { withRouter } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  GroundOverlay,
  Marker,
} from "react-google-maps";
import NumberFormat from "react-number-format";
// import { button } from "tabler-react";
import { ScrollElement, scroller } from "react-scroll";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import { Button } from "tabler-react";
import { BpProjects } from "../../services";

import "tabler-react/dist/Tabler.css";

Modal.setAppElement("#root");

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      ref={props.mapRef}
      mapTypeId={google.maps.MapTypeId.SATELLITE}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        signInControl: false,
        streetViewControl: false,
      }}
      defaultZoom={parseInt(props.data?.project?.nativeMapZoom || 18)}
      defaultCenter={{
        lat: parseFloat(props.data?.project.nativeMapLatitude || 0),
        lng: parseFloat(props.data?.project.nativeMapLongitute || 0) - 0.001,
      }}
    >
      {props.data && (
        <GroundOverlay
          defaultUrl={props.data?.project.nativeMapOverlayImageUrl}
          defaultBounds={
            new google.maps.LatLngBounds(
              new google.maps.LatLng(
                parseFloat(
                  props.data?.project.nativeMapOverlayImageLeftLatitude
                ),
                parseFloat(
                  props.data?.project.nativeMapOverlayImageLeftLongitude
                )
              ),
              new google.maps.LatLng(
                parseFloat(
                  props.data?.project.nativeMapOverlayImageRightLatitude
                ),
                parseFloat(
                  props.data?.project.nativeMapOverlayImageRightLongitude
                )
              )
            )
          }
        />
      )}
      {props.data &&
        props.data?.lots?.map((lot) => (
          <Marker
            key={lot.id}
            position={{
              lat: lot.data?.coordinate.latitude,
              lng: lot.data?.coordinate.longitude,
            }}
            motionTracking={false}
            icon={
              lot.id === props.selectedLot.id
                ? null
                : lot.status === "À VENDA" && lot.preReservationCode
                ? "https://www.guerini.com.br/mapadevendas/images/ICON_BLUE.png"
                : lot.status === "À VENDA"
                ? "https://www.guerini.com.br/mapadevendas/images/AVENDA.png"
                : lot.status === "VENDIDO"
                ? "https://www.guerini.com.br/mapadevendas/images/VENDIDO.png"
                : lot.status === "RESERVADO" &&
                  lot.preReservationCode &&
                  lot.preReservationStatus === "waiting_for_audit"
                ? "https://www.guerini.com.br/mapadevendas/images/ICON_CHECKLIST.png"
                : lot.status === "RESERVADO" &&
                  lot.preReservationCode &&
                  lot.preReservationStatus === "approved"
                ? "https://www.guerini.com.br/mapadevendas/images/ICON_CONFIRM_W.png"
                : lot.status === "RESERVADO" &&
                  lot.preReservationCode &&
                  lot.preReservationStatus === "reproved"
                ? "https://www.guerini.com.br/mapadevendas/images/ICON_X.png"
                : lot.status === "RESERVADO"
                ? "https://www.guerini.com.br/mapadevendas/images/RESERVADO.png"
                : "https://www.guerini.com.br/mapadevendas/images/SEMSTATUS.png"
            }
            onClick={() => props.onLotClick(lot)}
          />
        ))}
    </GoogleMap>
  ))
);

const LotCard = ScrollElement(
  ({
    name,
    id,
    item,
    project,
    selectedLot,
    setSelectedLot,
    onApproveClick,
    onReproveClick,
  }) => (
    <div
      name={name}
      id={id}
      className="card mb-2"
      style={
        selectedLot.id === item.id
          ? {
              boxShadow: "0px 12px 4px -4px rgba(32,32,32,0.5)",
              zIndex: 2,
              borderWidth: 1,
              borderColor: "rgba(32,32,32,0.5)",
            }
          : {
              cursor: "pointer",
            }
      }
      onClick={() => {
        if (selectedLot.id !== item.id) {
          setSelectedLot(item);
        }
      }}
    >
      <div
        className="d-flex justify-content-between m-3"
        style={{ borderBottom: "1px solid #DDD" }}
      >
        <div>
          <div
            className="primary-color"
            style={{ fontSize: "18px", fontWeight: 800 }}
          >
            {item.identifier}
          </div>
          <small className="d-block text-muted">
            {item.kind
              ? item.kind
              : project.kind === "residential"
              ? "Residencial"
              : project.kind === "business"
              ? "Empresarial"
              : project.kind === "area"
              ? "Área"
              : project.kind === "mixed"
              ? "Misto"
              : ""}
          </small>
        </div>
        <div className="text-muted text-right d-flex flex-column">
          <span
            style={
              item.preReservationCode
                ? {
                    textDecorationLine: "line-through",
                    textDecorationStyle: "solid",
                  }
                : {}
            }
            className={`${
              item.status === "À VENDA"
                ? "green-color"
                : item.status === "VENDIDO"
                ? "red-color"
                : item.status === "RESERVADO"
                ? "yellow-color"
                : ""
            }`}
          >
            {item.status}
          </span>
          {item.preReservationCode && (
            <small className="d-block muted">PRÉ-RESERVADO</small>
          )}
        </div>
      </div>
      <div className="px-3 pb-3" style={{ fontSize: "14px" }}>
        <div className="d-flex justify-content-between py-1">
          <span>Área Real (m²)</span>
          <NumberFormat
            value={item.area / 100}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            displayType="text"
            fixedDecimalScale={true}
            renderText={(value) => (
              <span style={{ textAlign: "right" }}>{value}</span>
            )}
          />
        </div>

        <div className="d-flex justify-content-between py-1">
          <span>Preço Final do Lote (R$/m²)</span>
          <NumberFormat
            value={item.areaPrice / 100}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            displayType="text"
            fixedDecimalScale={true}
            renderText={(value) => (
              <span style={{ textAlign: "right" }}>{value}</span>
            )}
          />
        </div>

        <div className="d-flex justify-content-between py-1">
          <span>Valor Final do Lote</span>
          <NumberFormat
            value={item.finalPrice / 100}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            displayType="text"
            fixedDecimalScale={true}
            prefix="R$ "
            renderText={(value) => (
              <span style={{ textAlign: "right", fontWeight: 700 }}>
                {value}
              </span>
            )}
          />
        </div>

        {item.data?.measurementLink != null &&
          item.data?.measurementLink.length > 0 && (
            <div className="d-flex justify-content-between py-1">
              <span>Medidas, Foto e Observações</span>
              <span style={{ textAlign: "right" }}>
                <a
                  target="_blank"
                  href={item.data?.measurementLink}
                  rel="noreferrer"
                >
                  Link
                </a>
              </span>
            </div>
          )}
        {item.status !== "À VENDA" && item.reservationCode && (
          <div className="d-flex justify-content-between py-1">
            <span>Protocolo</span>
            <span style={{ textAlign: "right" }}>
              <a target="_blank" href={item.link} rel="noreferrer">
                {item.reservationCode}
              </a>
            </span>
          </div>
        )}
        {item.status === "À VENDA" && item.preReservationCode && (
          <div className="d-flex justify-content-between py-1">
            <span>Protocolo Pré-reserva</span>
            <span style={{ textAlign: "right" }}>
              <a
                target="_blank"
                href={item.preReservationCode
                  ?.replace("GP", "https://redbooth.com/projects/")
                  .replace("T", "/tasks/")}
                rel="noreferrer"
              >
                {item.preReservationCode}
              </a>
            </span>
          </div>
        )}
      </div>
      {item.status === "À VENDA" &&
        item.preReservationCode &&
        item.preReservationStatus === "waiting_for_confirmation" && (
          <div
            className="d-flex justify-content-between mx-3 mb-1"
            style={{ borderTop: "1px solid #DDD" }}
          >
            <div className="text-center py-2" style={{ fontWeight: 600 }}>
              Aguardando o corretor concluir a pré-reserva
            </div>
          </div>
        )}
      {selectedLot.id === item.id &&
        item.status === "RESERVADO" &&
        item.preReservationStatus === "waiting_for_audit" && (
          <div className="d-flex mx-3" style={{ borderTop: "1px solid #DDD" }}>
            <div className="text-center py-2" style={{ fontWeight: 600 }}>
              Aguardando auditoria
            </div>
          </div>
        )}
      {selectedLot.id === item.id &&
        item.status === "RESERVADO" &&
        item.preReservationStatus === "approved" && (
          <div className="d-flex mx-3" style={{ borderTop: "1px solid #DDD" }}>
            <div className="text-center py-2" style={{ fontWeight: 600 }}>
              Reserva aprovada
            </div>
          </div>
        )}
      {selectedLot.id === item.id &&
        item.status === "RESERVADO" &&
        item.preReservationStatus === "reproved" && (
          <div className="d-flex mx-3" style={{ borderTop: "1px solid #DDD" }}>
            <div className="text-center py-2" style={{ fontWeight: 600 }}>
              Está unidade será baixada
            </div>
          </div>
        )}
      {selectedLot.id === item.id &&
        item.status === "RESERVADO" &&
        item.preReservationCode && (
          <div className="d-flex justify-content-between mx-3 mb-1">
            <div>
              <Button
                disabled={item.preReservationStatus === "reproved"}
                outline
                color="danger"
                onClick={() => onReproveClick(item)}
              >
                Reprovar
              </Button>
            </div>
            <div className="text-right">
              <Button
                disabled={item.preReservationStatus === "approved"}
                outline
                color="primary"
                onClick={() => onApproveClick(item)}
              >
                Aprovar
              </Button>
            </div>
          </div>
        )}
    </div>
  )
);

export const ProjectsMapPage = (props) => {
  const projectId = props.match.params.projectId;
  const alert = useAlert();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [selectedLot, setSelectedLot] = React.useState({});

  const mapRef = React.useRef(null);

  React.useEffect(() => {
    (async () => {
      if (projectId && projectId.length > 0) {
        const res = await BpProjects.getMapsById(projectId);
        setData(await res.data);
      }
    })();
  }, [projectId]);

  React.useEffect(() => {
    if (selectedLot.id && data && mapRef.current) {
      scroller.scrollTo(`${selectedLot.id}`, {
        containerId: "lots",
        smooth: true,
      });

      mapRef.current.panTo(
        new google.maps.LatLng(
          selectedLot.data?.coordinate.latitude,
          selectedLot.data?.coordinate.longitude - 0.001
        )
      );
    }
  }, [selectedLot]);

  return data ? (
    <div style={{ height: "100%", width: "100%" }}>
      <Map
        mapRef={mapRef}
        data={data}
        selectedLot={selectedLot}
        onLotClick={setSelectedLot}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB4kgwYSTYdTsT6efHQLYOPZUuB-S5zTNI&v=3.42&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <div
        style={{
          backgroundColor: "#EEF0F2",
          bottom: 0,
          left: 0,
          position: "absolute",
          top: 0,
          width: 384,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            left: 0,
            overflowY: "scroll",
            position: "absolute",
            right: 0,
            top: 0,
            boxShadow: "0px 8px 4px -4px rgba(32,32,32,0.2)",
            borderBottom: "1px solid rgba(32,32,32,0.2)",
            zIndex: 10,
            overflowY: "none",
          }}
        >
          <div>
            <div
              className="d-flex justify-content-between p-3 pb-1"
              style={{ borderBottom: "1px solid #DDD" }}
            >
              <div>
                <div
                  className="primary-color"
                  style={{ fontSize: "18px", fontWeight: 800 }}
                >
                  {data?.project?.name}
                </div>
                <small className="d-block">
                  {data?.project?.kind === "residential" ? "Residencial" : ""}
                  {data?.project?.kind === "business" ? "Empresarial" : ""}
                  {data?.project?.kind === "area" ? "Área" : ""}
                  {data?.project?.kind === "mixed" ? "Misto" : ""}
                </small>
              </div>
              <div>
                <Button
                  link
                  className="px-0"
                  onClick={async () => {
                    window.location.reload();
                  }}
                >
                  Recarregar
                </Button>
              </div>
            </div>
            <div className="px-3">
              <Button
                link
                className="px-0"
                onClick={async () => {
                  const password = window.prompt(
                    "Digite a senha para iniciar a próxima onda"
                  );
                  if (password === null) return;
                  if (password !== "997718451")
                    return window.alert("Senha não confere");

                  if (
                    window.confirm(
                      "Tem certeza que quer começar a próxima onda?"
                    )
                  ) {
                    const res = await BpProjects.nextWave(projectId);
                    if (res.status === 200) {
                      alert.success("Próxima onda iniciada com sucesso");
                      window.location.reload();
                    } else {
                      alert.error("Não foi possível iniciar a próxima onda.");
                    }
                  }
                }}
              >
                Iniciar Próxima Onda
              </Button>
            </div>
          </div>
          <div></div>
        </div>
        <div
          id="lots"
          style={{
            backgroundColor: "#EEF0F2",
            bottom: 0,
            left: 0,
            overflowY: "scroll",
            paddingTop: 8,
            position: "absolute",
            right: 0,
            top: 102,
          }}
        >
          {data?.lots?.map((lot) => (
            <LotCard
              key={`${data?.project?.id}_${lot.id}`}
              name={`${lot.id}`}
              item={lot}
              project={data?.project}
              selectedLot={selectedLot}
              setSelectedLot={setSelectedLot}
              onApproveClick={async (item) => {
                if (
                  window.confirm(
                    `Tem certeza que quer APROVAR o lote ${item.identifier}?`
                  )
                ) {
                  const res = await BpProjects.approvePreReservation(
                    projectId,
                    item.id
                  );
                  if (res.status === 200) {
                    alert.success("Reserva aprovado com sucesso!");
                    window.location.reload();
                  } else {
                    alert.error("Não foi possível aprovar o reserva.");
                  }
                }
              }}
              onReproveClick={async (item) => {
                const reason = window.prompt(
                  `Tem certeza que quer REPROVAR o lote ${item.identifier}? Escreva o motivo abaixo`
                );
                if (reason === null) return;
                if (reason.length === 0)
                  return window.alert("Motivo não pode ser em branco");

                const res = await BpProjects.reprovePreReservation(
                  projectId,
                  item.id,
                  {
                    reason,
                  }
                );
                if (res.status === 200) {
                  alert.success("Reserva reprovado com sucesso!");
                  window.location.reload();
                } else {
                  alert.error("Não foi possível reprovar o reserva.");
                }
              }}
            />
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            minWidth: 500,
            transform: "translate(-50%, -50%)",
            zIndex: 100,
          },
          overlay: {
            zIndex: 100,
          },
        }}
        contentLabel="Simular"
      ></Modal>
    </div>
  ) : (
    <div
      className="page"
      style={{
        backgroundImage: "url('./assets/images/landing_background.jpg')",
        backgroundSize: "cover",
      }}
    />
  );
};

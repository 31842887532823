import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

import { red, green, blue } from '@material-ui/core/colors';

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as Delete,
  Create as Create
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { AdminUsersService } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteAdmin, setDeleteAdmin] = React.useState(null);
  const [receiveInput, setReceiveInput] = React.useState("");

  React.useEffect(() => {
    (async () => {
      const resList = await AdminUsersService.list(page);
      setData(resList.data);
    })();
  }, [page]);

  const handleClickOpen = ({ id, name }) => {
    setOpen(true);
    setDeleteAdmin({ id, name })
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteAdmin(null)
  };

  const handleRemove = async () => {
    await AdminUsersService.deleteById(deleteAdmin.id);

    alert("Administrador excluido(a) com sucesso")

    handleClose()
    const resList = await AdminUsersService.list(page);
    setData(resList.data);
  }

  const handleInput = (event) => {
    setReceiveInput(event.target.value)
  }

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Data de Criação</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow hover>
                    <TableCell component="th" id={index} scope="row">
                      <Customer>
                        <Avatar>{row.customerAvatar}</Avatar>
                        <Box ml={3}>
                          <strong>{row.name}</strong>
                          <br />
                          {row.email}
                        </Box>
                      </Customer>
                    </TableCell>
                    <TableCell>
                      {row.document}
                    </TableCell>
                    <TableCell>
                      {format(parseISO(row.createdAt), "PPPP", {
                        locale: pt,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={NavLink}
                        to={`/platform/${row.id}/edit`}
                        style={{ color: blue[700] }}
                      >
                        <Create />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickOpen({ id: row.id, name: row.name })}
                        style={{ color: red[300] }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          deleteAdmin?.name && deleteAdmin?.id && (
            <Dialog
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>
                {"Deseja excluir " + `${deleteAdmin?.name}` + "?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Para excluir <strong style={{ color: "red" }}>{deleteAdmin?.name}</strong> <strong>definitivamente</strong> digite o nome da Imobiliaria no campo abaixo
                </DialogContentText>
              </DialogContent>
              <div align="center">
                <TextField
                  labelId="label"
                  my={2}
                  variant="outlined"
                  onChange={(event) => handleInput(event)}
                  value={receiveInput}>
                </TextField>
              </div>
              <DialogActions>
                {receiveInput == deleteAdmin?.name ? (
                  <Button style={{ color: "red" }} onClick={handleRemove} color="primary">
                    Deletar
                  </Button>
                ) : ""}
                <Button onClick={handleClose} color="primary" autoFocus>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const AdminUsersIndexPage = () => {
  const history = useHistory();

  return (
    <Box p={8}>
      <Helmet title="Administradores" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Administradores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Inicio
            </Link>
            <Typography>Portal do Administrador</Typography>
            <Typography>Administradores</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            href="/platform/admins/new"
          >
            Cadastrar Administrador
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
    Box,
    Avatar,
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    Grid,
    Link,
    TextField as MuiTextField,
    Typography,
    Paper as MuiPaper,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpBrokers } from "../../services";

import BrokersForm from "../../components/BrokersForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
    const [brokerData, setBrokerData] = React.useState([]);
    const [accreditationData, setAccreditationData] = React.useState([]);

    const { brokerId, accreditationId } = useParams();

    React.useEffect(() => {
        (async () => {
            const resList = await BpBrokers.getById(brokerId);
            setBrokerData(resList.data);
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            const resList = await BpBrokers.getAccreditation(brokerId, accreditationId);
            setAccreditationData(resList.data);
        })();
    }, []);


    const handleUpdate = async (form) => {
        const { status } = form

        await BpBrokers.updateAccreditation(brokerId, accreditationId, { status });

        alert("Empreendimento atualizado com sucesso")
    }

    const data = {
        ...brokerData,
        ...accreditationData,
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Preencha os dados abaixo para editar o empreendimento
                </Typography>

                <BrokersForm
                    data={data}
                    buttonName="Atualizar"
                    disabled={true}
                    buttonOnClick={handleUpdate}
                />
            </CardContent>
        </Card>
    );
}

export const BrokersAccreditationsPage = () => {
    const { brokerId } = useParams();
    return (
        <Box p={8}>
            <Helmet title="Settings" />

            <Typography variant="h3" gutterBottom display="inline">
                Novo empreendimento
            </Typography>

            <Breadcrumbs mt={2}>
                <Link component={NavLink} exact to="/">
                    Inicio
                </Link>
                <Link component={NavLink} exact to={`/bp/brokers/${brokerId}/lots`}>
                    Lista de empreendimento
                </Link>
                <Typography>Cadastro de empreendimentos</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Private />
                </Grid>
            </Grid>
        </Box>
    );
};

import React, { useState, useEffect } from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { red, green, blue } from "@material-ui/core/colors";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { Add as AddIcon, Delete, Create } from "@material-ui/icons";

import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";

import { spacing } from "@material-ui/system";

import { BpBanners } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteBanner, setDeleteBanner] = React.useState(null);
  const [receiveInput, setReceiveInput] = React.useState("");

  React.useEffect(() => {
    (async () => {
      const resList = await BpBanners.list(page);
      setData(resList.data);
    })();
  }, [page]);

  const handleClickOpen = ({ id, title }) => {
    setOpen(true);
    setDeleteBanner({ id, title });
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteBanner(null);
  };

  const handleInput = (event) => {
    setReceiveInput(event.target.value);
  };

  const handleRemove = async () => {
    await BpBanners.remove(deleteBanner.id);

    alert("Banner excluido com sucesso");

    handleClose();
    const resList = await BpBanners.list(page);
    setData(resList.data);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Preview</TableCell>
                <TableCell>Título</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Ordem</TableCell>
                <TableCell>Ativo</TableCell>
                <TableCell align="center" style={{ minWidth: "150px" }}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow hover={true}>
                    <TableCell id={index} scope="row">
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          alt="background image"
                          style={{ objectFit: "cover", width: 98 }}
                          src={row.image.url}
                        />
                      </div>
                    </TableCell>

                    <TableCell id={index} scope="row">
                      <Box>
                        <strong>{row.title}</strong>
                      </Box>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Box>{row.url}</Box>
                    </TableCell>
                    <TableCell id={index} scope="row">
                      <Box>{row.order}</Box>
                    </TableCell>

                    <TableCell id={index} scope="row">
                      <Box>
                        {row.enabled ? (
                          <Box style={{ paddingRight: "5px" }}>
                            <CheckIcon style={{ color: green[700] }} />
                          </Box>
                        ) : (
                          <Box style={{ paddingRight: "5px" }}>
                            <BlockIcon style={{ color: red[400] }} />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: "150px" }}>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/bp/banners/${row.id}/edit`}
                        style={{ color: blue[700] }}
                      >
                        <Create />
                      </IconButton>
                      <IconButton
                        aria-label="details"
                        onClick={() =>
                          handleClickOpen({ id: row.id, title: row.title })
                        }
                        style={{ color: red[300] }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {deleteBanner?.title && deleteBanner?.id && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {"Deseja excluir " + `${deleteBanner?.title}` + "?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para excluir o banner{" "}
                <strong style={{ color: "red" }}>{deleteBanner?.title}</strong>{" "}
                <strong>definitivamente</strong> digite o nome do projeto no
                campo abaixo
              </DialogContentText>
            </DialogContent>
            <div align="center">
              <TextField
                labelId="label"
                my={2}
                variant="outlined"
                onChange={(event) => handleInput(event)}
                value={receiveInput}
              ></TextField>
            </div>

            <DialogActions>
              {receiveInput == deleteBanner?.title ? (
                <Button
                  style={{ color: "red" }}
                  onClick={handleRemove}
                  color="primary"
                >
                  Deletar
                </Button>
              ) : (
                ""
              )}

              <Button onClick={handleClose} color="primary" autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <TablePagination
          labelDisplayedRows={({ from, to, count }) =>
            `Página ${page + 1} de ${Math.ceil(count / 6)}`
          }
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const BannersIndexPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Banners" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Banners
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Banners</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            href="/bp/banners/new"
          >
            Novo
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
    Box,
    Avatar,
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    Grid,
    Link,
    TextField as MuiTextField,
    Typography,
    Paper as MuiPaper,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpProjects } from "../../services";

import ProjectsForm from "../../components/ProjectsForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
    const [data, setData] = React.useState([]);

    const { projectId } = useParams();

    React.useEffect(() => {
        (async () => {
            const resList = await BpProjects.getById(projectId);
            setData(resList.data);
        })();
    }, []);


    const handleSubmit = async (form) => {
        await BpProjects.edit(projectId, form);

        const resProject = await BpProjects.getById(projectId);
        setData(resProject.data);

        alert("Empreendimento atualizado com sucesso")
    }
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Preencha os dados abaixo para criar um novo empreendimento
                </Typography>

                <ProjectsForm
                    data={data}
                    projectId={projectId}
                    buttonName="Atualizar"
                    handleSubmit={handleSubmit}
                    disabledFieldURL />
            </CardContent>
        </Card>
    );
}

export const ProjectsEditFormPage = () => {
    return (
        <Box p={8}>
            <Helmet title="Settings" />

            <Typography variant="h3" gutterBottom display="inline">
                Edição de empreendimentos
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} exact to="/">
                    Inicio
                </Link>
                <Link component={NavLink} exact to="/bp/projects">
                    Empreendimentos
                </Link>
                <Typography>Edição de empreendimentos</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Private />
                </Grid>
            </Grid>
        </Box>
    );
};

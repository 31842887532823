import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import NumberFormat from "react-number-format";

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpLots } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  align-items: center;
  display: flex;
`;

const PageSize = 500;

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [summary, setSummary] = React.useState({});
  const { projectId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpLots.list(projectId, page, 500);

      const statuses = resList.data.rows.reduce((acc, row) => {
        acc[row.preReservationStatus] =
          (acc[row.preReservationStatus] || 0) + 1;
        acc[row.status] = (acc[row.status] || 0) + 1;
        return acc;
      }, {});
      setSummary(statuses);

      setData(resList.data);
    })();
  }, [page]);

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {Object.keys(summary).map((key) => (
                    <TableCell>{key.toLocaleUpperCase()}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.keys(summary).map((key) => (
                    <TableCell>{summary[key]}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <div>
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>IDENTIFICAÇÃO DA UNIDADE</TableCell>
                  <TableCell>
                    STATUS
                    <br />
                    ÁREA REAL (M²)
                  </TableCell>
                  <TableCell>
                    VALOR FINAL DO LOTE
                    <br />
                    PREÇO FINAL DO LOTE (R$/M²)
                  </TableCell>
                  <TableCell>PRÉ-RESERVA</TableCell>
                  <TableCell>RESERVA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.rows?.map((row, index) => {
                  return (
                    <TableRow
                      style={{
                        background: row.status.includes("VENDA")
                          ? "#f0fff0"
                          : "#ffeeed",
                      }}
                    >
                      <TableCell id={index} scope="row">
                        <Box>
                          <strong>{row.identifier}</strong>
                          <br />
                          <Typography>Comercial - Frente | Avenida</Typography>
                        </Box>
                      </TableCell>
                      <TableCell id={index} scope="row">
                        <strong>{row.status}</strong>
                        <br />
                        <NumberFormat
                          value={row.area / 100}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          displayType="text"
                          fixedDecimalScale="true"
                        />
                      </TableCell>
                      <TableCell id={index} scope="row">
                        <strong>
                          <NumberFormat
                            value={row.finalPrice / 100}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale="2"
                            displayType="text"
                            fixedDecimalScale="true"
                            prefix="R$ "
                          />
                        </strong>
                        <br />
                        <NumberFormat
                          value={row.areaPrice / 100}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale="2"
                          displayType="text"
                          fixedDecimalScale="true"
                          prefix="R$ "
                        />
                      </TableCell>
                      <TableCell id={index} scope="row">
                        <strong>{row.preReservationStatus}</strong>
                        <br />

                        <a href={row.link} target="_blank" rel="noreferrer">
                          {row.preReservationCode}
                        </a>
                      </TableCell>
                      <TableCell id={index} scope="row">
                        <Box>{row.reservationCode}</Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[PageSize]}
            component="div"
            count={data.count || 0}
            rowsPerPage={PageSize}
            page={page}
            onChangePage={(_, page) => setPage(page)}
          />
        </Paper>
      </div>
    </>
  );
}

export const LotsIndexPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Lotes do empreendimento" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lotes do empreendimento
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Empreendimentos</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

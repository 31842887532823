import React from "react";
import styled from "styled-components/macro";

import {
  Card,
  CardActionArea,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { spacing } from "@material-ui/system";

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  height: 200px;
  padding-top: 42px;
  text-align: center;
`;

const Typography = styled(MuiTypography)(spacing);

export const NewFormCard = ({ onClick }) => {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <AddCircle color="primary" fontSize="large" />
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            color="primary"
            mt={4}
            mx={4}
          >
            Cadastrar um novo formulário
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

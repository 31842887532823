import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";

import { spacing } from "@material-ui/system";
import { CssBaseline, withWidth, Paper as MuiPaper } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Paper = styled(MuiPaper)(spacing);

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const ProtectedBlankLayout = ({ children, routes, width }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>{children}</AppContent>
    </Root>
  );
};

export default withWidth()(ProtectedBlankLayout);

import React from "react";
import styled, { withTheme } from "styled-components/macro";

import { Button, Grid, InputLabel } from "@material-ui/core";

const GNDocumentUploader = (props) => {
  const onDownloadClick = () => {
    window.open(props?.src?.data);
  };

  const onFileChange = (e) => {
    const { files } = e.target;

    if (files.length) {
      const file = files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (props.onChange)
          props.onChange({
            name: file.name,
            type: file.type,
            data: reader.result,
          });
      };
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item container md={4} alignItems="center">
        <InputLabel>{props.label || "Documento"}</InputLabel>
      </Grid>
      <Grid item container md={4} alignItems="center">
        <Button
          variant="contained"
          component="span"
          size="small"
          onClick={onDownloadClick}
          disabled={!props?.src?.data}
          fullWidth
        >
          {"Baixar Arquivo Atual"}
        </Button>
      </Grid>
      <Grid item container md={4} alignItems="center">
        <input
          type="file"
          style={{ display: "none" }}
          id="gn-document-uploader"
          multiple={props.multiple}
          onChange={onFileChange}
        />

        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
          fullWidth
        >
          <label htmlFor="gn-document-uploader" style={{ margin: 0 }}>
            {props.buttonLabel || "Alterar o Arquivo"}
          </label>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withTheme(GNDocumentUploader);

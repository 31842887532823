import { api } from "./BackendService";

export const create = (data) => api.post("/brokers", data);
export const edit = (id, data) => api.put(`/brokers/${id}`, data);
export const getById = (id) => api.get(`/brokers/${id}`);
export const getAccreditation = (brokerId, accreditationId) =>
  api.get(`/brokers/${brokerId}/accreditations/${accreditationId}`);
export const updateAccreditation = (brokerId, accreditationId, data) =>
  api.put(`/brokers/${brokerId}/accreditations/${accreditationId}`, data);
export const removeAccreditation = (brokerId, accreditationId) =>
  api.delete(`/brokers/${brokerId}/accreditations/${accreditationId}`);
export const list = (page, search) =>
  api.get("/brokers", { params: { page, search } });
export const listLogs = (id, page) =>
  api.get(`/brokers/${id}/roulette-logs`, { params: { page: page } });

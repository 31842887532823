import { api } from "./BackendService";

export const create = (brokerId, data) =>
  api.post(`/brokers/${brokerId}/roulettes`, data);
export const edit = (brokerId, id, data) =>
  api.put(`/brokers/${brokerId}/roulettes/${id}`, data);
export const getById = (brokerId, id) =>
  api.get(`/brokers/${brokerId}/roulettes/${id}`);
export const list = (brokerId, page) =>
  api.get(`/brokers/${brokerId}/roulettes`, { params: { page: page } });

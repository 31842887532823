import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button as MuiButton,
  Card,
  CardContent,
  Grid,
  TextField as MuiTextField,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import GNDocumentUploader from "./GNDocumentUploader";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function RealStateForm({ data, buttonName, handleSubmit }) {
  const [abbreviation, setAbbreviation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [creci, setCreci] = useState("");
  const [responsibleName, setResponsibleName] = useState("");
  const [responsibleMobilePhone, setResponsibleMobilePhone] = useState("");
  const [responsibleEmail, setResponsibleEmail] = useState("");
  const [status, setStatus] = useState("");
  const [enabled, setEnabled] = useState("");
  const [adminOnly, setAdminOnly] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [certificationDocument, setCertificationDocument] = useState({
    data: null,
  });

  useEffect(() => {
    setAbbreviation(data?.abbreviation || "");
    setCompanyName(data?.companyName || "");
    setName(data?.name || "");
    setDocument(data?.document || "");
    setCreci(data?.creci || "");
    setResponsibleName(data?.responsibleName || "");
    setResponsibleMobilePhone(data?.responsibleMobilePhone || "");
    setResponsibleEmail(data?.responsibleEmail || "");
    setStatus(data?.status || "");
    setEnabled(data?.enabled ? "yes" : "no");
    setAdminOnly(data?.adminOnly ? "yes" : "no");
    setCertificationDocument({
      data: data?.Document?.url,
    });
    setAddressStreet(data?.addressStreet || "");
    setAddressComplement(data?.addressComplement || "");
    setAddressCity(data?.addressCity || "");
    setAddressNumber(data?.addressNumber || "");
    setAddressState(data?.addressState || "");
    setAddressZipcode(data?.addressZipcode || "");
    setAddressDistrict(data?.addressDistrict || "");
  }, [data]);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const form = {
      abbreviation,
      companyName,
      name,
      document,
      creci,
      responsibleName,
      status,
      enabled: enabled === "yes" ? true : false,
      adminOnly: adminOnly === "yes" ? true : false,
      responsibleMobilePhone,
      responsibleEmail,
      certificationDocument: certificationDocument?.data
        ? certificationDocument
        : null,
      addressStreet,
      addressComplement,
      addressCity,
      addressNumber,
      addressState,
      addressZipcode,
      addressDistrict,
    };
    try {
      await handleSubmit(form);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="abbreviation"
                    label="Abreviação ou Sigla da Imobiliária"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAbbreviation, event.target.value)
                    }
                    value={abbreviation}
                  />
                </Grid>
              </Grid>

              <TextField
                id="companyname"
                label="Digite a razão social da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setCompanyName, event.target.value)
                }
                value={companyName}
                my={2}
              />

              <TextField
                id="name"
                label="Digite o nome de exibição da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setName, event.target.value)}
                value={name}
                my={2}
              />

              <TextField
                id="cnpj"
                label="Digite o CNPJ da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setDocument, event.target.value)
                }
                value={document}
                my={2}
              />

              <TextField
                id="creci"
                label="Digite o CRECI PJ da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setCreci, event.target.value)}
                value={creci}
                my={2}
              />

              <TextField
                id="name2"
                label="Digite o nome do responsável da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setResponsibleName, event.target.value)
                }
                value={responsibleName}
                my={2}
              />

              <TextField
                id="name2"
                label="Celular do responsável da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setResponsibleMobilePhone, event.target.value)
                }
                value={responsibleMobilePhone}
                my={2}
              />

              <TextField
                id="name2"
                label="Email do responsável da imobiliariária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setResponsibleEmail, event.target.value)
                }
                value={responsibleEmail}
                my={2}
              />

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel id="label">Situação</InputLabel>
                  <TextField
                    labelId="label"
                    id="select"
                    select
                    my={2}
                    fullWidth
                    onChange={(event) =>
                      handleChange(setStatus, event.target.value)
                    }
                    value={status}
                    variant="outlined"
                  >
                    <MenuItem value="wait">Aguardando validação</MenuItem>
                    <MenuItem value="disapproved">Reprovado</MenuItem>
                    <MenuItem value="approved">Aprovado</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel id="label">Ativo</InputLabel>
                  <TextField
                    onChange={(event) =>
                      handleChange(setEnabled, event.target.value)
                    }
                    value={enabled}
                    labelId="label"
                    id="select"
                    select
                    my={2}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="yes">Sim</MenuItem>
                    <MenuItem value="no">Não</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <GNDocumentUploader
                label="Certidão de Regularidade do CRECI"
                src={certificationDocument}
                onChange={setCertificationDocument}
              />
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>

              <TextField
                id="addressStreent"
                label="Endereço"
                variant="outlined"
                fullWidth
                my={2}
                onChange={(event) =>
                  handleChange(setAddressStreet, event.target.value)
                }
                value={addressStreet}
              />

              <Grid container spacing={6}>
                <Grid item md={4}>
                  <TextField
                    id="addressNumber"
                    label="Número"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressNumber, event.target.value)
                    }
                    value={addressNumber}
                  />
                </Grid>

                <Grid item md={4}>
                  <TextField
                    id="addressComplement"
                    label="Complemento"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressComplement, event.target.value)
                    }
                    value={addressComplement}
                  />
                </Grid>

                <Grid item md={4}>
                  <TextField
                    id="addressDistrict"
                    label="Bairro"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressDistrict, event.target.value)
                    }
                    value={addressDistrict}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={4}>
                  <TextField
                    id="city"
                    label="Cidade"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressCity, event.target.value)
                    }
                    value={addressCity}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="state"
                    label="Estado"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressState, event.target.value)
                    }
                    value={addressState}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="addressZipcode"
                    label="CEP"
                    variant="outlined"
                    fullWidth
                    my={2}
                    onChange={(event) =>
                      handleChange(setAddressZipcode, event.target.value)
                    }
                    value={addressZipcode}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button
            onClick={submitForm}
            variant="contained"
            color="primary"
            mt={3}
            fullWidth
          >
            {buttonName}
          </Button>
        </form>
      )}
    </>
  );
}

export default RealStateForm;

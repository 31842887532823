import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";

import { BpBrokers } from "../../services";
import BrokersForm from "../../components/BrokersForm";

import {
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Link,
} from "@material-ui/core";
import styled from "styled-components/macro";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function Private() {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");

  const { brokerId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpBrokers.getById(brokerId);
      setData(resList.data);
    })();
  }, []);

  useEffect(() => {
    setName(data?.name || "");
  }, [data]);

  const handleSubmit = async (form) => {
    await BpBrokers.edit(brokerId, form);

    alert("Corretor atualizado com sucesso");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preencha os dados abaixo para editar os dados do corretor
        </Typography>
        <BrokersForm
          data={data}
          buttonName="Atualizar"
          handleSubmit={handleSubmit}
        />
      </CardContent>
    </Card>
  );
}

export const BrokersEditFormPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Editar corretor
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/brokers">
          Corretores
        </Link>
        <Typography>Edição de corretores</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import { red, blue } from '@material-ui/core/colors';

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as Delete,
  Map as Map,
  Create as Create,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { BpBrokers, BpLotsBrokers } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const { brokerId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpLotsBrokers.list(brokerId, page);
      setData(resList.data);
    })();
  }, [page]);

  const handleClickOpen = (accreditationId) => {
    setOpen(true);
    setDeleteId(accreditationId)
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null)
  };

  const handleRemove = async () => {
    await BpBrokers.removeAccreditation(brokerId, deleteId);

    alert("Empreendimento do corretor excluido com sucesso")

    handleClose()

    const resList = await BpLotsBrokers.list(brokerId, page);
    setData(resList.data);
  }

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell>EMPREENDIMENTOS</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row, index) => {
                return (
                  <TableRow
                    style={{
                      background: row.status.includes("enabled")
                        ? "#f0fff0"
                        : "#ffeeed",
                    }}
                  >
                    <TableCell align="left" id={index} scope="row">
                      <strong>{row.project.name}</strong>
                    </TableCell>
                    <TableCell id={index} scope="row">

                      {row.status === "enabled"
                        ? "Habilitado"
                        : "Não habilitado"}

                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        style={{ color: blue[700] }}
                        component={NavLink}
                        to={`/bp/brokers/${brokerId}/accreditations/${row.id}`}
                      >
                        <Create />
                      </IconButton>
                      <IconButton
                        style={{ color: "red" }}
                        onClick={() => handleClickOpen(row.id)}
                      >
                        <Delete />
                      </IconButton>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                      >
                        <DialogTitle>{"Deseja excluir?"}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Tem certeza que deseja excluir?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleRemove} color="primary">
                            Sim
                          </Button>
                          <Button onClick={handleClose} color="primary" autoFocus>
                            Não
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => `Página ${page + 1} de ${Math.ceil(count / 6)}`}
          rowsPerPageOptions={[6]}
          component="div"
          count={data.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, page) => setPage(page)}
        />
      </Paper>
    </div>
  );
}

export const LotsBrokersIndexPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Lotes do empreendimento" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lista de Empreendimentos Credenciados
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/bp/brokers">
              Corretores
            </Link>
            <Typography>Empreendimentos Credenciados</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl,
  FormGroup,
  Grid,
  Checkbox,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  PanoramaFishEye,
  CheckBoxOutlineBlank,
  CloseOutlined,
  AddOutlined,
  RadioButtonChecked,
  CheckBox,
  Today,
  QueryBuilder,
  ArrowDropDownCircle,
  Subject,
  Label,
  Storage,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { CpDatabasesService } from "../../services";

// Components
import DropdownList from "../../components/DropdownList";

const Box = styled(MuiBox)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "22px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const FormsOpenPage = ({
  data,
  buttonName,
  handleSubmit,
  formTitle,
}) => {
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [privateForm, setPrivateForm] = useState(false);
  const [fields, setFields] = useState([]);
  const [webhookUrl, setWebHookUrl] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [databases, setDataBases] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const resList = await CpDatabasesService.get();
      setDataBases(resList.data);
    })();
  }, []);

  useEffect(() => {
    // const dataFields = data?.fields?.length ? JSON.parse(data.fields) : [];

    setTitle(data?.title || "");
    setDescription(data?.description || "");
    setWebHookUrl(data?.webhookUrl || "");
    setPrivateForm(data?.private || "");

    setFields(data?.fields?.length ? JSON.parse(data.fields) : []);
  }, [data]);

  const handleClick = () => {
    const form = {
      title,
      description,
      webhookUrl,
      private: privateForm,
      fields: JSON.stringify(fields),
    };

    handleSubmit(form);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  //funções de adição, update e exclusão

  const onCreateFieldClick = () => {
    setFields([
      ...fields,
      {
        id: fields.length + 1,
        label: `Campo ${fields.length + 1}`,
        placeholder: "",
        required: true,
        kind: "text",
        data: {},
      },
    ]);
  };

  const onRemoveFieldClick = (id) => {
    const newFields = fields.filter((f) => f.id !== id);

    setFields(newFields);
  };

  const onFieldChange = (index, attribute, value) => {
    const newFields = [...fields];

    newFields[index][attribute] = value;

    setFields(newFields);
  };

  const classes = useStyles();
  return (
    <Box p={8}>
      <Helmet title={formTitle} />

      <Typography variant="h3" gutterBottom display="inline">
        {formTitle}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/">
          Formulários
        </Link>
        <Typography>{formTitle}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Informações Gerais
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={8}>
                  <TextField
                    id="title"
                    label="Título"
                    placeholder="Formulário Exemplo"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    my={2}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={privateForm}
                          onChange={(event) =>
                            setPrivateForm(event.target.checked)
                          }
                          name="private"
                        />
                      }
                      label="Formulário Privado"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    label="Descrição"
                    id="description"
                    multiline={true}
                    rows={3}
                    rowsMax={4}
                    variant="outlined"
                    fullWidth
                    placeholder="Descreva qual a finalidade desse formulário"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    my={2}
                  />

                  <TextField
                    id="webhook"
                    label="Webhook"
                    placeholder="Adicione aqui o link do webhook"
                    variant="outlined"
                    fullWidth
                    value={webhookUrl}
                    onChange={(e) => setWebHookUrl(e.target.value)}
                    my={2}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Configuração de Campos
              </Typography>

              <Grid container spacing={6} mt={10}>
                {fields.map((field, index) => (
                  <Grid key={`field_${index}`} item xs={12} spacing={6}>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" m={2}>
                          <InputLabel id={`field_${index}_kind_label`}>
                            Tipo
                          </InputLabel>
                          <Select
                            labelId={`field_${index}_kind_label`}
                            id={`field_${index}_kind`}
                            value={field.kind}
                            onChange={(e) => {
                              if (e.target.value === "multiple") {
                                onFieldChange(index, "data", { options: [] });
                              }
                              if (e.target.value === "checkbox") {
                                onFieldChange(index, "data", { options: [] });
                              }
                              if (e.target.value === "dropdownlist") {
                                onFieldChange(index, "data", {
                                  options: [],
                                  numberOfExtraParameters: 0,
                                });
                              }
                              if (e.target.value === "multipleGrid") {
                                onFieldChange(index, "data", {
                                  rows: [],
                                  columns: [],
                                });
                              }
                              if (e.target.value === "selectionGrid") {
                                onFieldChange(index, "data", {
                                  rows: [],
                                  columns: [],
                                });
                              }
                              if (e.target.value === "scale") {
                                onFieldChange(index, "data", { options: [] });
                              }

                              onFieldChange(index, "kind", e.target.value);
                            }}
                            label="Tipo"
                          >
                            <MenuItem value="text">
                              <Subject
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Texto
                            </MenuItem>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="multiple"
                            >
                              <RadioButtonChecked
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Multipla escolha
                            </MenuItem>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="checkbox"
                            >
                              <CheckBox
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Caixas de seleção
                            </MenuItem>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="dropdownlist"
                            >
                              <ArrowDropDownCircle
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Lista suspensa
                            </MenuItem>
                            <Divider></Divider>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="day"
                            >
                              <Today
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Data
                            </MenuItem>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="time"
                            >
                              <QueryBuilder
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Hora
                            </MenuItem>
                            <Divider></Divider>
                            <MenuItem
                              style={{ paddingLeft: "10px", fontSize: "14px" }}
                              value="bd"
                            >
                              <Storage
                                style={{
                                  verticalAlign: "middle",
                                  paddingRight: "10px",
                                  fontSize: "32px",
                                }}
                              />
                              Banco de dados
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id={`field_${index}_label`}
                          label="Nome do Campo"
                          placeholder={`Nome do campo #${index}`}
                          variant="outlined"
                          fullWidth
                          value={field.label}
                          onChange={(e) =>
                            onFieldChange(index, "label", e.target.value)
                          }
                          my={2}
                        />
                      </Grid>

                      <Grid item md={8}>
                        <TextField
                          id={`field_${index}_placeholder`}
                          //label="Placeholder"
                          placeholder={`Descrição do campo ${index}`}
                          variant="outlined"
                          fullWidth
                          value={field.placeholder}
                          onChange={(e) =>
                            onFieldChange(index, "placeholder", e.target.value)
                          }
                          my={2}
                        />
                      </Grid>

                      {field.kind === "text" && (
                        <React.Fragment>
                          <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined" m={2}>
                              <InputLabel id={`field_${index}_required_label`}>
                                Obrigatório
                              </InputLabel>
                              <Select
                                labelId={`field_${index}_required_label`}
                                id={`field_${index}_required`}
                                value={field.required}
                                onChange={(e) =>
                                  onFieldChange(
                                    index,
                                    "required",
                                    e.target.value
                                  )
                                }
                                label="Obrigatório"
                              >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined" m={2}>
                              <InputLabel
                                id={`field_${index}_autocapitalize_label`}
                              >
                                Maiusculo
                              </InputLabel>
                              <Select
                                labelId={`field_${index}_autocapitalize_label`}
                                id={`field_${index}_autocapitalize`}
                                value={field.data.autocapitalize || "words"}
                                onChange={(e) =>
                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    autocapitalize: e.target.value,
                                  })
                                }
                                label="Obrigatório"
                              >
                                <MenuItem value="off">off</MenuItem>
                                <MenuItem value="none">none</MenuItem>
                                <MenuItem value="on">on</MenuItem>
                                <MenuItem value="sentences">sentences</MenuItem>
                                <MenuItem value="words">words</MenuItem>
                                <MenuItem value="characters">
                                  characters
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      )}

                      {field.kind === "multiple" && (
                        <Grid item md={4}>
                          {field?.data?.options?.map((item) => (
                            <div
                              style={{ paddingTop: "12px" }}
                              key={`id-${item.id}`}
                            >
                              <PanoramaFishEye
                                style={{
                                  paddingRight: "6px",
                                  alignItems: "center",
                                  fontSize: "28px",
                                }}
                              />
                              <TextField
                                name={item.id}
                                value={item.name}
                                onChange={(e) => {
                                  const newList = [...field.data.options].map(
                                    (option) => {
                                      if (option.id === item.id) {
                                        return {
                                          ...option,
                                          name: e.target.value,
                                        };
                                      }

                                      return option;
                                    }
                                  );

                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    options: newList,
                                  });
                                }}
                              />

                              <CloseOutlined
                                style={{ color: "#fa0000" }}
                                onClick={() => {
                                  const newList = field.data.options.filter(
                                    (option) => option.id !== item.id
                                  );

                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    options: newList,
                                  });
                                }}
                              />
                            </div>
                          ))}

                          <div style={{ paddingTop: "12px" }}>
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => {
                                const optionsLength = field.data.options.length;
                                const newIndex = optionsLength
                                  ? field.data.options[optionsLength - 1].id + 1
                                  : optionsLength + 1;
                                onFieldChange(index, "data", {
                                  ...field.data,
                                  options: [
                                    ...field.data.options,
                                    {
                                      id: newIndex,
                                      name: `Opção ${optionsLength + 1}`,
                                    },
                                  ],
                                });
                              }}
                            >
                              <AddOutlined />
                              Adicionar opção
                            </Button>
                          </div>
                        </Grid>
                      )}

                      {field.kind === "checkbox" && (
                        <Grid item md={4}>
                          {field?.data?.options?.map((item) => (
                            <div
                              style={{ paddingTop: "12px" }}
                              key={`id-${item.id}`}
                            >
                              <CheckBoxOutlineBlank
                                style={{
                                  paddingRight: "6px",
                                  alignItems: "center",
                                  fontSize: "28px",
                                }}
                              />
                              <TextField
                                name={item.id}
                                value={item.name}
                                onChange={(e) => {
                                  const newList = [...field.data.options].map(
                                    (option) => {
                                      if (option.id === item.id) {
                                        return {
                                          ...option,
                                          name: e.target.value,
                                        };
                                      }

                                      return option;
                                    }
                                  );

                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    options: newList,
                                  });
                                }}
                              />

                              <CloseOutlined
                                style={{ color: "#fa0000" }}
                                onClick={() => {
                                  const newList = field.data.options.filter(
                                    (option) => option.id !== item.id
                                  );

                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    options: newList,
                                  });
                                }}
                              />
                            </div>
                          ))}

                          <div style={{ paddingTop: "12px" }}>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => {
                                const optionsLength = field.data.options.length;
                                const newIndex = optionsLength
                                  ? field.data.options[optionsLength - 1].id + 1
                                  : optionsLength + 1;
                                onFieldChange(index, "data", {
                                  ...field.data,
                                  options: [
                                    ...field.data.options,
                                    {
                                      id: newIndex,
                                      name: `Opção ${optionsLength + 1}`,
                                    },
                                  ],
                                });
                              }}
                            >
                              <AddOutlined />
                              Adicionar opção
                            </Button>
                          </div>
                        </Grid>
                      )}

                      {field.kind === "dropdownlist" && (
                        <DropdownList
                          index={index}
                          field={field}
                          onFieldChange={onFieldChange}
                        />
                      )}
                      {field.kind === "upload" && (
                        <Grid item md={12} my={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={checked}
                                  onChange={toggleChecked}
                                />
                              }
                              label="Permitir apenas tipos de arquivo específicos"
                            />
                          </FormGroup>

                          <Grid item md={4}>
                            <Grid item md={8}>
                              <Typography>Número máximo de arquivos</Typography>
                            </Grid>

                            <TextField
                              labelId="label"
                              id="select"
                              value="10"
                              select
                              my={2}
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value="10">5</MenuItem>
                              <MenuItem value="30">10</MenuItem>
                              <MenuItem value="40">15</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item md={4}>
                            <Grid item md={8}>
                              <Typography>Tamanho máximo do arquivo</Typography>
                            </Grid>

                            <TextField
                              labelId="label"
                              id="select"
                              value="1"
                              select
                              my={2}
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value="1">1 MB</MenuItem>
                              <MenuItem value="10">10 MB</MenuItem>
                              <MenuItem value="100">100 MB</MenuItem>
                              <MenuItem value="1000">1 GB</MenuItem>
                              <MenuItem value="10000">10 GB</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      )}

                      {field.kind === "day" && (
                        <form className={classes.container} noValidate>
                          <TextField
                            id="date"
                            type="date"
                            defaultValue="desabled"
                            className={classes.textField}
                          />
                        </form>
                      )}
                      {field.kind === "time" && (
                        <form className={classes.container} noValidate>
                          <TextField
                            id="time"
                            type="time"
                            defaultValue="desabled"
                            className={classes.textField}
                          />
                        </form>
                      )}
                      {field.kind === "scale" && (
                        <>
                          <Grid item md={2}>
                            <TextField
                              labelId="label"
                              id="select"
                              select
                              my={2}
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item md={2}>
                            <TextField
                              labelId="label"
                              id="select"
                              select
                              my={2}
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="6">6</MenuItem>
                              <MenuItem value="7">7</MenuItem>
                              <MenuItem value="8">8</MenuItem>
                              <MenuItem value="9">9</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item md={12}>
                            <TextField
                              id="sobrenome"
                              placeholder="marcador opcional"
                              variant="outlined"
                              my={2}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              id="sobrenome"
                              placeholder="marcador opcional"
                              variant="outlined"
                              my={2}
                            />
                          </Grid>
                        </>
                      )}

                      {field.kind === "multipleGrid" && (
                        <>
                          <Grid item md={4}>
                            {field?.data?.rows?.map((item) => (
                              <div style={{ paddingTop: "12px" }} key={item.id}>
                                <label style={{ paddingRight: "12px" }}>
                                  {index}.
                                </label>
                                <TextField
                                  name={item.id}
                                  value={item.name}
                                  onChange={(e) => {
                                    const newList = [...field.data.rows];

                                    newList[item.id - 1].name = e.target.value;

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      rows: newList,
                                    });
                                  }}
                                />

                                <CloseOutlined
                                  style={{ color: "#fa0000" }}
                                  onClick={() => {
                                    const newList = field.data.rows.filter(
                                      (row) => row.id !== item.id
                                    );

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      rows: newList,
                                    });
                                  }}
                                />
                              </div>
                            ))}

                            <div style={{ paddingTop: "12px" }}>
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    rows: [
                                      ...field.data.rows,
                                      {
                                        id: field.data.rows.length + 1,
                                        name: `Opção ${
                                          field.data.rows.length + 1
                                        }`,
                                      },
                                    ],
                                  });
                                }}
                              >
                                <AddOutlined />
                                Adicionar linha
                              </Button>
                            </div>
                          </Grid>

                          <Grid item md={4}>
                            {field?.data?.columns?.map((item) => (
                              <div style={{ paddingTop: "12px" }} key={item.id}>
                                <PanoramaFishEye
                                  style={{
                                    paddingRight: "6px",
                                    alignItems: "center",
                                    fontSize: "28px",
                                  }}
                                />
                                <TextField
                                  name={item.id}
                                  value={item.name}
                                  onChange={(e) => {
                                    const newList = [...field.data.columns];

                                    newList[item.id - 1].name = e.target.value;

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      columns: newList,
                                    });
                                  }}
                                />

                                <CloseOutlined
                                  style={{ color: "#fa0000" }}
                                  onClick={() => {
                                    const newList = field.data.columns.filter(
                                      (column) => column.id !== item.id
                                    );

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      columns: newList,
                                    });
                                  }}
                                />
                              </div>
                            ))}

                            <div style={{ paddingTop: "12px" }}>
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    columns: [
                                      ...field.data.columns,
                                      {
                                        id: field.data.columns.length + 1,
                                        name: `Opção ${
                                          field.data.columns.length + 1
                                        }`,
                                      },
                                    ],
                                  });
                                }}
                              >
                                <AddOutlined />
                                Adicionar coluna
                              </Button>
                            </div>
                          </Grid>
                        </>
                      )}

                      {field.kind === "bd" && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" m={2}>
                              <InputLabel id={`field_${index}_database_label`}>
                                Qual banco você deseja usar?
                              </InputLabel>
                              <Select
                                labelId={`field_${index}_database_label`}
                                id={`field_${index}_database`}
                                value={field.database}
                                onChange={(e) =>
                                  onFieldChange(
                                    index,
                                    "database",
                                    e.target.value
                                  )
                                }
                                label="Qual banco você deseja?"
                              >
                                {databases.length &&
                                  databases.map((database) => (
                                    <MenuItem value={database}>
                                      {database}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      )}

                      {field.kind === "selectionGrid" && (
                        <>
                          <Grid item md={4}>
                            {field?.data?.rows?.map((item) => (
                              <div style={{ paddingTop: "12px" }} key={item.id}>
                                <label style={{ paddingRight: "12px" }}>
                                  {index}.
                                </label>
                                <TextField
                                  name={item.id}
                                  value={item.name}
                                  onChange={(e) => {
                                    const newList = [...field.data.rows];

                                    newList[item.id - 1].name = e.target.value;

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      rows: newList,
                                    });
                                  }}
                                />

                                <CloseOutlined
                                  style={{ color: "#fa0000" }}
                                  onClick={() => {
                                    const newList = field.data.rows.filter(
                                      (row) => row.id !== item.id
                                    );

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      rows: newList,
                                    });
                                  }}
                                />
                              </div>
                            ))}

                            <div style={{ paddingTop: "12px" }}>
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    rows: [
                                      ...field.data.rows,
                                      {
                                        id: field.data.rows.length + 1,
                                        name: `Opção ${
                                          field.data.rows.length + 1
                                        }`,
                                      },
                                    ],
                                  });
                                }}
                              >
                                <AddOutlined />
                                Adicionar linha
                              </Button>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            {field?.data?.columns?.map((item) => (
                              <div style={{ paddingTop: "12px" }} key={item.id}>
                                <CheckBoxOutlineBlank
                                  style={{
                                    paddingRight: "6px",
                                    alignItems: "center",
                                    fontSize: "28px",
                                  }}
                                />
                                <TextField
                                  name={item.id}
                                  value={item.name}
                                  onChange={(e) => {
                                    const newList = [...field.data.columns];

                                    newList[item.id - 1].name = e.target.value;

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      columns: newList,
                                    });
                                  }}
                                />

                                <CloseOutlined
                                  style={{ color: "#fa0000" }}
                                  onClick={() => {
                                    const newList = field.data.columns.filter(
                                      (column) => column.id !== item.id
                                    );

                                    onFieldChange(index, "data", {
                                      ...field.data,
                                      columns: newList,
                                    });
                                  }}
                                />
                              </div>
                            ))}

                            <div style={{ paddingTop: "12px" }}>
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                  onFieldChange(index, "data", {
                                    ...field.data,
                                    columns: [
                                      ...field.data.columns,
                                      {
                                        id: field.data.columns.length + 1,
                                        name: `Opção ${
                                          field.data.columns.length + 1
                                        }`,
                                      },
                                    ],
                                  });
                                }}
                              >
                                <AddOutlined />
                                Adicionar coluna
                              </Button>
                            </div>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        style={{
                          marginTop: "22px",
                          color: "#fa0000",
                        }}
                        size="small"
                        onClick={() => onRemoveFieldClick(field.id)}
                      >
                        <CloseOutlined />

                        <div>Excluir campo</div>
                      </Button>
                      <Divider mt={6} />
                    </Grid>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={onCreateFieldClick}
                  >
                    Adicionar Campo
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container justify="flex-end">
          <Button variant="contained" color="primary" onClick={handleClick}>
            {buttonName}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

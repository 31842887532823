import { api } from "./BackendService";

export const create = (data) => api.post("/real-states", data);
export const edit = (id, data) => api.put(`/real-states/${id}`, data);
export const getById = (id) => api.get(`/real-states/${id}`);
export const remove = (id) => api.delete(`/real-states/${id}`);
export const list = (page, search) =>
  api.get("/real-states", { params: { page, search } });
export const listBrokers = (id, page, search) =>
  api.get(`/real-states/${id}/brokers`, { params: { page, search } });

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import App from "./App";

import { Provider } from "react-redux";
import store from "./redux/store/index";

const alertProviderOptions = {
  position: positions.TOP_CENTER,
  timeout: 10000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
  },
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...alertProviderOptions}>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);

import React from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpBanners } from "../../services";

import BannersForm from "../../components/BannersForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
  const createBanners = async (form) => {
    await BpBanners.create({
      ...form,
    });
    alert("Banner cadastrado com sucesso");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preencha os dados abaixo para criar um novo banner
        </Typography>

        <BannersForm
          buttonOnClick={createBanners}
          buttonName="Cadastrar"
          handleSubmit={createBanners}
        />
      </CardContent>
    </Card>
  );
}

export const BannersFormPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Novo
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/banners">
          Banners
        </Link>
        <Typography>Cadastro de banners</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </Box>
  );
};

export const getUser = () => new Promise((resolve) => {

    const response = {
        data: {
            name: "Rielly Magalhães"
        }
    };

    setTimeout(() => {
        resolve(response)
    }, 100);
});
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { FormCard, NewFormCard } from "../components";
import { CpFormsService } from "../../services";

const Box = styled(MuiBox)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

export const FormsIndexPage = () => {
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({});
  const [deleteId, setDeleteId] = React.useState(null);

  useEffect(() => {
    (async () => {
      const resList = await CpFormsService.list();
      setData(resList.data);
    })();
  }, []);


  const handleRemove = async () => {
    await CpFormsService.remove(deleteId);
    handleClose();
    alert("Formulário excluido com sucesso")

    const resList = await CpFormsService.list();
    setData(resList.data);

  }

  const handleRemoveAction = (formId) => {
    setOpen(true);
    setDeleteId(formId)
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null)
  };

  const duplicateForm = async (id) => {
    const resList = await CpFormsService.getById(id);

    const data = resList.data;

    await CpFormsService.create(data);

    alert("Formulário duplicado com sucesso")

    const response = await CpFormsService.list();
    setData(response.data);
  }

  return (
    <Box p={8}>
      <Helmet title="Formulários" />
      <Typography variant="h3" gutterBottom display="inline">
        Formulários
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Início
        </Link>
        <Typography>Formulários</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <NewFormCard
            onClick={() => {
              history.push("/cp/forms/new");
            }}
          />
        </Grid>
        {data.rows?.map(({ title, description, id }) => {
          return (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <FormCard
                title={title}
                description={description}
                handleRemoveAction={handleRemoveAction}
                duplicateForm={duplicateForm}
                id={id}
              />
            </Grid>
          );
        })}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{"Deseja excluir?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja excluir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemove} color="primary">
            Sim
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

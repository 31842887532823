/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { blue, green, red } from "@material-ui/core/colors";

import {
  Avatar,
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";

import { spacing } from "@material-ui/system";
import { BpBrokers } from "../../services";

const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const BigAvatar = styled(Avatar)`
  height: 65px;
  width: 65px;
`;

export const BrokersIndexPage = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const resList = await BpBrokers.list(page, search);
      setData(resList.data);
    })();
  }, []);

  const onSubmitSearch = async (term) => {
    setSearch(term);

    const resList = await BpBrokers.list(0, term);
    setData(resList.data);

    setPage(0);
  };

  const handlePageChange = async (page) => {
    const resList = await BpBrokers.list(page, search);
    setData(resList.data);

    setPage(page);
  };

  const autoCompleteChange = (event) => {
    onSubmitSearch(event?.target?.value);
  };

  return (
    <Box p={8}>
      <Helmet title="Empreendimentos" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Corretores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Corretores</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="filter"
            label="Filtro"
            variant="outlined"
            fullWidth
            onChange={autoCompleteChange}
            my={2}
          />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">
                        Empreendimentos
                        <br />
                        Credenciados
                      </TableCell>
                      <TableCell align="center">
                        Empreendimentos
                        <br />
                        Roleta Virtual
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: "150px" }}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!data?.rows?.length && (
                      <h3 style={{ padding: 12 }}>
                        Corretor(a) não encontrado(a)
                      </h3>
                    )}
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow id={index} hover={true}>
                          <TableCell scope="row">
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {row.image?.url ? (
                                <img
                                  src={row.image?.url}
                                  width="65"
                                  height="65"
                                  style={{ borderRadius: 40 }}
                                />
                              ) : (
                                <BigAvatar alt={row.name} />
                              )}
                              <Box ml={3}>
                                <strong>{row.name}</strong>
                                <Box>{row.email}</Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell scope="row">
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: "5px",
                                  width: 24,
                                  textAlign: "center",
                                }}
                              >
                                {row.accreditation.enabled}
                              </Box>
                              <Box style={{ paddingRight: "16px" }}>
                                <CheckIcon style={{ color: green[700] }} />
                              </Box>
                              <Box
                                style={{
                                  paddingRight: "5px",
                                  width: 24,
                                  textAlign: "center",
                                }}
                              >
                                {row.accreditation.blocked}
                              </Box>
                              <Box>
                                <BlockIcon style={{ color: red[400] }} />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell id={index} scope="row" align="center">
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: "5px",
                                  width: 24,
                                  textAlign: "center",
                                }}
                              >
                                {row.roulette.enabled}
                              </Box>
                              <Box style={{ paddingRight: "16px" }}>
                                <CheckIcon style={{ color: green[700] }} />
                              </Box>
                              <Box
                                style={{
                                  paddingRight: "5px",
                                  width: 24,
                                  textAlign: "center",
                                }}
                              >
                                {row.roulette.blocked}
                              </Box>
                              <Box>
                                <BlockIcon style={{ color: red[400] }} />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: "180px" }}
                          >
                            <IconButton
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/roulette-logs`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.ThumbUp />
                            </IconButton>
                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/roulettes`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.Work />
                            </IconButton>

                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/lots`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.RemoveRedEye />
                            </IconButton>

                            <IconButton
                              aria-label="details"
                              component={NavLink}
                              to={`/bp/brokers/${row.id}/edit`}
                              style={{ color: blue[700] }}
                            >
                              <Icons.Create />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelDisplayedRows={({ from, to, count }) =>
                  `Página ${page + 1} de ${Math.ceil(count / 6)}`
                }
                rowsPerPageOptions={[6]}
                component="div"
                count={data.count || 0}
                rowsPerPage={6}
                page={page}
                onChangePage={(_, page) => handlePageChange(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { spacing } from "@material-ui/system";

import {
  Avatar,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider as MuiDivider,
  Card as MuiCard,
  Grid,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import styled from "styled-components/macro";

import { IconButton } from "@material-ui/core";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GNAvatarUploader from "./GNAvatarUploader";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  height: 65px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
  width: 65px;
`;

function BrokersForm({ data, buttonName, handleSubmit, disabled }) {
  const [name, setName] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [creci, setCreci] = useState("");
  const [document, setDocument] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [tshirtSize, setTshirtSize] = useState("");
  const [email, setEmail] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [realState, setRealState] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [realStateDocument, setRealStateDocument] = useState("");
  const [landlinePhone, setLandlinePhone] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [realStateCreci, setRealStateCreci] = useState("");
  const [enabled, setEnabled] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [realStateResponsibleName, setRealStateResponsibleName] = useState("");
  const [rouletteEnabled, setRouletteEnabled] = useState("");
  const [addressState, setAddressState] = useState("");
  const [
    realStateResponsibleMobilePhone,
    setRealStateResponsibleMobilePhone,
  ] = useState("");
  const [realStateResponsibleEmail, setRealStateResponsibleEmail] = useState(
    ""
  );
  const [status, setStatus] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [intern, setIntern] = useState(false);
  const [image, setImage] = useState(null);

  const onAvatarChange = (data) => setImage(data?.data);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const onIntern = (event) => {
    setIntern(event.target.value);
  };

  useEffect(() => {
    setName(data?.name || "");
    setAddressZipcode(data?.addressZipcode || "");
    setCreci(data?.creci || "");
    setDocument(data?.document || "");
    setAddressStreet(data?.addressStreet || "");
    setTshirtSize(data?.tshirtSize || "");
    setEmail(data?.email || "");
    setAddressNumber(data?.addressNumber || "");
    setRealState(data?.realState || "");
    setMobilePhone(data?.mobilePhone || "");
    setAddressComplement(data?.addressComplement || "");
    setRealStateDocument(data?.realStateDocument || "");
    setLandlinePhone(data?.landlinePhone || "");
    setAddressDistrict(data?.addressDistrict || "");
    setRealStateCreci(data?.realStateCreci || "");
    setEnabled(data?.enabled ? "yes" : "no");
    setAddressCity(data?.addressCity || "");
    setRealStateResponsibleName(data?.realStateResponsibleName || "");
    setRouletteEnabled(data?.rouletteEnabled ? "yes" : "no");
    setAddressState(data?.addressState || "");
    setRealStateResponsibleMobilePhone(
      data?.realStateResponsibleMobilePhone || ""
    );
    setRealStateResponsibleEmail(data?.realStateResponsibleEmail || "");
    setStatus(data?.status ? "yes" : "no");
    setIntern(data?.intern ? "yes" : "no");
    setImage(data?.image?.url);
  }, [data]);

  const submitForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const form = {
      name,
      addressZipcode,
      creci,
      document,
      addressStreet,
      tshirtSize,
      email,
      addressNumber,
      realState,
      mobilePhone,
      addressComplement,
      realStateDocument,
      landlinePhone,
      addressDistrict,
      realStateCreci,
      enabled,
      addressCity,
      realStateResponsibleName,
      rouletteEnabled,
      addressState,
      realStateResponsibleMobilePhone,
      realStateResponsibleEmail,
      status,
      intern: intern === "yes" ? true : false,
      image: image === data?.image?.url ? null : image,
    };
    try {
      await handleSubmit(form);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };
  return (
    <>
      {isSubmitting ? (
        <CircularProgress variant="indeterminate" color="red" />
      ) : (
        <form>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <TextField
                id="Nome"
                label="Nome"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setName, event.target.value)}
                value={name}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="CEP"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressZipcode, event.target.value)
                }
                value={addressZipcode}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="CRECI"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setCreci, event.target.value)}
                value={creci}
                my={2}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <TextField
                id="Nome"
                label="CPF"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setDocument, event.target.value)
                }
                value={document}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="Rua"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressStreet, event.target.value)
                }
                value={addressStreet}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="tamanho da camiseta"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setTshirtSize, event.target.value)
                }
                value={tshirtSize}
                my={2}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <TextField
                id="Nome"
                label="Email"
                variant="outlined"
                fullWidth
                onChange={(event) => handleChange(setEmail, event.target.value)}
                value={email}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="numero"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressNumber, event.target.value)
                }
                value={addressNumber}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="nome da imobiliaria"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setRealState, event.target.value)
                }
                value={realState}
                my={2}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <TextField
                id="Nome"
                label="celular"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setMobilePhone, event.target.value)
                }
                value={mobilePhone}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="complemento"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressComplement, event.target.value)
                }
                value={addressComplement}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="cnpj da imobiliaria"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setRealStateDocument, event.target.value)
                }
                value={realStateDocument}
                my={2}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <TextField
                id="Nome"
                label="telefone"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setLandlinePhone, event.target.value)
                }
                value={landlinePhone}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="bairro"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressDistrict, event.target.value)
                }
                value={addressDistrict}
                my={2}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="CRECI da imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setRealStateCreci, event.target.value)
                }
                value={realStateCreci}
                my={2}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {!disabled && (
              <Grid item md={4}>
                <InputLabel id="label">Ativo</InputLabel>
                <TextField
                  onChange={(event) =>
                    handleChange(setEnabled, event.target.value)
                  }
                  value={enabled}
                  labelId="label"
                  id="select"
                  select
                  my={2}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="yes">Sim</MenuItem>
                  <MenuItem value="no">Não</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="Cidade"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressCity, event.target.value)
                }
                value={addressCity}
                my={5}
                disabled={disabled}
              />
            </Grid>
            {disabled && (
              <Grid item md={4}>
                <InputLabel id="label">Status</InputLabel>
                <TextField
                  onChange={(event) =>
                    handleChange(setStatus, event.target.value)
                  }
                  value={status}
                  labelId="label"
                  id="select"
                  select
                  my={2}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="yes">Habilitado</MenuItem>
                  <MenuItem value="no">Bloqueado</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="Nome do responsável pela Imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setRealStateResponsibleName, event.target.value)
                }
                value={realStateResponsibleName}
                my={5}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {!disabled && (
              <Grid item md={4}>
                <InputLabel id="label">Habilitar roleta</InputLabel>
                <TextField
                  onChange={(event) =>
                    handleChange(setRouletteEnabled, event.target.value)
                  }
                  value={rouletteEnabled}
                  labelId="label"
                  id="select"
                  select
                  my={2}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="yes">Sim</MenuItem>
                  <MenuItem value="no">Não</MenuItem>
                </TextField>
              </Grid>
            )}

            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="Estado"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setAddressState, event.target.value)
                }
                value={addressState}
                my={5}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={4}>
              <TextField
                id="CRECI"
                label="Celular do responsável pela Imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(
                    setRealStateResponsibleMobilePhone,
                    event.target.value
                  )
                }
                value={realStateResponsibleMobilePhone}
                my={5}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="sobrenome"
                label="Email do responsável pela Imobiliária"
                variant="outlined"
                fullWidth
                onChange={(event) =>
                  handleChange(setRealStateResponsibleEmail, event.target.value)
                }
                value={realStateResponsibleEmail}
                my={2}
                disabled={disabled}
              />
            </Grid>

            <Grid item md={4}>
              <TextField
                label="Estagiário"
                onChange={onIntern}
                value={intern}
                labelId="label"
                id="select"
                select
                my={2}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="yes">Sim</MenuItem>
                <MenuItem value="no">Não</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={4}>
              <GNAvatarUploader
                src={image}
                onChange={onAvatarChange}
                buttonLabel="Alterar foto do corretor"
              />
            </Grid>
          </Grid>

          <Button
            onClick={submitForm}
            variant="contained"
            fullWidth
            color="primary"
            mt={3}
          >
            {buttonName}
          </Button>
        </form>
      )}
    </>
  );
}

BrokersForm.defaultProps = {
  disabled: false,
};

export default BrokersForm;

import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpRealStates } from "../../services";

import RealStateForm from "../../components/RealStatesForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});

  const { realstateId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpRealStates.getById(realstateId);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form) => {
    await BpRealStates.edit(realstateId, form);

    alert("Imobiliaria atualizada com sucesso");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preencha os dados abaixo para criar uma nova imobiliária
        </Typography>
        <RealStateForm
          data={data}
          buttonName="Atualizar"
          handleSubmit={handleSubmit}
        />
      </CardContent>
    </Card>
  );
}

export const RealStatesEditFormPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Editar
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/realstates">
          Imobiliárias
        </Link>
        <Typography>Edição de imobiliária</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </Box>
  );
};
